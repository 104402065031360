.mobile-cta {
    display: flex;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    .cta-button {
        width: 50%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        font-size: 16px;
        line-height: 20px;
        font-weight: $font-medium;
        &.book-a-demo {
            color: white;
            background-color: #1E1E1E;
            img {
                width: 14px;
                height: auto;
            }
        }
        &.sign-up {
            color: $font-color-primary;
            background-color: #00FC70;
            img {
                width: 16px;
                height: auto;
                margin-top: 3px;
            }
        }
    }
}