// Only Homepage styles here
// Unique page styles should be kept to a minimum
// try to create re-useable classes that can be used anywhere


//@TODO clean up home scss for animation
main.home {
	section.hero {
		margin-top: 90px;
		@include media-breakpoint-down(lg) {
			margin-top: 40px;
		}
		@include media-breakpoint-down(md) {
			margin-top: 55px;
		}
		.cloud {
			position: relative;
			.card-pill {
				opacity: 0;
				@include media-breakpoint-down(lg) { display: none; }
				&#card-pill-1 {
					top: -25px;
					left: 75px;
					@include media-breakpoint-down(xxl) {
						left: 40px;
					}
				}
				&#card-pill-2 {
					top: 20px;
					right: 40px;
					@include media-breakpoint-down(xxl) {
						right: -10px;
					}
					@include media-breakpoint-down(xl) {
						right: -140px;
					}
				}
				&#card-pill-3 {
					top: 135px;
					left: 10px;
				}
				&#card-pill-4 {
					top: 216px;
					right: 78px;
					@include media-breakpoint-down(xl) {
						right: -100px;
					}
				}
			}
			.hero-text {
				width: 50%;
				margin: 0 auto;
				text-align: center;
				@include media-breakpoint-down(lg) {
					width: 100%;
				}
				h1 {
					width: 595px;
					margin: 0 auto 25px auto;
					@include media-breakpoint-down(lg) {
						width: 330px;
						margin: 0 auto 17px auto;
					}
				}
				.lead {
					opacity: 0;
					font-weight: $font-regular;
					@include media-breakpoint-only(xxl) {
						padding: 0 30px;
					}
					@include media-breakpoint-down(lg) {
						width: 67%;
						margin: 0 auto;
					}
					@include media-breakpoint-down(md) {
						width: 77%;
					}
					@include media-breakpoint-only(sm) {
						width: 100%;
					}
				}
			}
		}

		.get-started {
			opacity: 0;
			position:relative;
			z-index: 10;
			width: 75%;
			padding: 45px 60px 60px;
			margin: 0px auto 95px;
			background-color: #AAFF3F;
			display: flex;
			border-radius: 35px;
			box-shadow: 1px 2px 4px #0000001A;
			@include media-breakpoint-down(xl) {
				padding: 45px 50px 55px;
				width: 83%;
			}
			@include media-breakpoint-down(lg) {
				margin: 35px auto 70px;
				padding: 35px 35px 50px;
				width: 100%;
			}
			@include media-breakpoint-down(md) {
				margin: 35px 0 70px;
				padding: 30px 45px 50px;
				flex-direction: column;
				width: 100%;
				left: 0px;
			}
			@include media-breakpoint-down(sm) {
				padding: 30px 40px 50px;
				width: calc(100% + 30px);
				left: -15px;
			}
			.content {
				flex-basis: 50%;
				padding-right: 16px;
				@include media-breakpoint-down(xl) {
					padding-right: 0px;
				}
				.metrics {
					margin-top: 30px;
					display: flex;
					text-align: center;
					@include media-breakpoint-down(lg) {
						margin-top: 20px;
					}
					@include media-breakpoint-down(md) {
						display: none;
					}
					p {
						color: #343348;
						font-size: 16px;
						line-height: 23px;
						font-weight: $font-medium;
						@include media-breakpoint-down(lg) {
							font-size: 14px;
						}
					}
					p.lead {
						color: $font-color-primary;
						font-size: 35px;
						font-weight: $font-bold;
						line-height: 42px;
						margin-bottom: 8px;
						@include media-breakpoint-down(lg) {
							font-size: 30px;
							line-height: 40px;
							margin-bottom: 5px;
						}
					}
					img {
						position: absolute;
					}
					div {
						padding: 16px 24px;
					}
				}
			}
			.form {
				width: 300px;
				margin: 20px 0 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				@include media-breakpoint-down(lg) {
					width: 280px;
				}
				@include media-breakpoint-down(md) {
					padding-left: 0px;
					margin: 30px auto 0;
				}
				.btn {
					width: 100%;
					height: 56px;
					&.btn-secondary {
						border: 1px solid transparent;
						margin-bottom: 0px;
						position: relative;
						img {
							position: absolute;
							left: 24px;
							padding-top: 16px;
						}
					}

				}
				div {
					width: 100%;
					margin: 22px 0;
					@include media-breakpoint-down(lg) {
						margin: 12px 0;
					}
					@include media-breakpoint-down(md) {
						margin: 18px 0;
					}
					span {
						opacity: 31%;
						font-size: 18px;
						font-weight: $font-bold;
						text-transform: uppercase;
					}
					hr {
						margin: 0px;
						width: 126px;
						height: 1px;
						background-color: $font-color-primary;
						opacity: 12%;
					}
				}
				.custom-input {
					&.mdc-text-field.mdc-text-field--outlined {
						width: 100%;
						margin-bottom: 22px;
						@include media-breakpoint-down(md) {
							margin-bottom: 25px;
						}
					}
				}
			}
		}
	}

	.hero-card-container {
		margin-top: 32px;
	}

	.blob-shape-left {
		position: absolute;
		top: -30px;
		left: -550px;
		height: 690px;
		width: auto;
		opacity: 0;
		@include media-breakpoint-down(sm) {
			top: 410px;
			height: 400px;
			left: -280px;
		}
	}

	section.testimonials {
		margin-bottom: 110px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 90px;
		}
		.slider-pagination {
			bottom: -69px;
			@include media-breakpoint-down(lg) {
				bottom: -66px;
			}
		}
		.link-with-circle {
			@include media-breakpoint-down(md) {
				margin: 110px auto 0;
			}
		}

	}

	section.process {
		margin-bottom: 100px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 150px;
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 90px;
		}

		.pin-card {
			overflow: hidden;
		}

		.section-heading { 
			.line { 
				width: 823px;
				@include media-breakpoint-down(xl) {
					width: 645px;
				}
				@include media-breakpoint-down(lg) {
					width: 510px;
				}
				@include media-breakpoint-down(md) {
					left: 175px;
				}
			}
		}
		.step-1 {
			overflow: hidden;
			.company-card-cloud {
				position: relative;
				.company-card-1 {
					top: 65px;
					left: 100px;
					@include media-breakpoint-down(lg) {
						top: 0px;
						left: 250px;
					}
					@include media-breakpoint-down(md) {
						top: 2px;
						left: 76px;
					}
				}
				.company-card-2 {
					top: 65px;
					left: 380px;
					@include media-breakpoint-down(lg) {
						top: 0px;
						left: 465px;
					}
					@include media-breakpoint-down(md) {
						top: 2px;
						left: 291px;
					}
				}
				.company-card-3 {
					top: 166px;
					left: 28px;
					@include media-breakpoint-down(lg) {
						top: 70px;
						left: 190px;
					}
					@include media-breakpoint-down(md) {
						top: 73px;
						left: 16px;
					}
				}
				.company-card-4 {
					top: 166px;
					left: 308px;
					@include media-breakpoint-down(lg) {
						top: 0px;
						left: 680px;
					}
					@include media-breakpoint-down(md) {
						top: 73px;
						left: 231px;
					}
				}
				.company-card-5 {
					top: 166px;
					left: 588px;
					@include media-breakpoint-down(lg) {
						top: 70px;
						left: 405px;
					}
					@include media-breakpoint-down(md) {
						top: 141px;
						left: 50px;
					}
				}
				.company-card-6 {
					top: 267px;
					left: 100px;
					@include media-breakpoint-down(lg) {
						top: 70px;
						left: 620px;
					}
					@include media-breakpoint-down(md) {
						top: 141px;
						left: 265px;
					}
				}
				.company-card-7 {
					top: 267px;
					left: 380px;
					@include media-breakpoint-down(lg) {
						top: 140px;
						left: 224px;
					}
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
				.company-card-8 {
					top: 368px;
					left: 28px;
					@include media-breakpoint-down(lg) {
						top: 140px;
						left: 440px;
					}
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
				.company-card-9 {
					top: 368px;
					left: 308px;
					@include media-breakpoint-down(lg) {
						top: 140px;
						left: 654px;
					}
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
				.company-card-10 {
					top: 368px;
					left: 588px;
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
				.company-card-11 {
					top: 470px;
					left: 192px;
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
				.company-card-12 {
					top: 470px;
					left: 472px;
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
			}
		}
		.step-2 {
			overflow: hidden;
			@include media-breakpoint-down(lg) {
				min-height: 625px;
			}
			.visuals-container {
				position: relative;
				height: 100%;
				@include media-breakpoint-down(lg) {
					height: 286px;
				}
				@include media-breakpoint-down(md) {
					height: 220px;
				}
				img {
					position: absolute;
					&.highlighter {
						height: 430px;
						width: auto;
						right: 0px;
						bottom: 95px;
						z-index: 2;
						@include media-breakpoint-down(xl) {
							right: -145px;
						}
						@include media-breakpoint-down(lg) {
							height: 240px;
							right: 0px;
							top: 20px;
						}
						@include media-breakpoint-down(md) {
							height: 190px;
							right: 0px;
							top: 44px;
						}
						@include media-breakpoint-down(sm) {
							top: 3px;
						}
					}
					&.person {
						height: 430px;
						width: auto;
						right: 181px;
						bottom: 0px;
						z-index: 1;
						@include media-breakpoint-down(xl) {
							right: 35px;
						}
						@include media-breakpoint-down(lg) {
							height: 210px;
							top: 80px;
							right: 114px;
						}
						@include media-breakpoint-down(md) {
							height: 170px;
							top: 90px;
							right: 90px;
						}
						@include media-breakpoint-down(sm) {
							top: 50px;
							right: 88px;
						}
					}
				}
				.text-container {
					bottom: -115px;
					right: -530px;
					width: 85%;
					@include media-breakpoint-down(xxl) {
						width: 100%;
						bottom: -170px;
						right: -435px;
					}
					@include media-breakpoint-down(xl) {
						width: 140%;
						bottom: -120px;
						right: -380px;
					}
					@include media-breakpoint-down(lg) {
						width: 45%;
						right: -570px;
						top: 58px;
					}
					@include media-breakpoint-down(md) {
						width: 70%;
						right: -425px;
						top: 88px;
					}
					@include media-breakpoint-down(sm) {
						width: 85%;
						right: -275px;
						top: 36px;
					}
					sup {
						top: -10px;
						font-size: 17px;
						@include media-breakpoint-down(lg) {
							font-size: 9px;
						}
					}
					p {
						font-size: 34px;
						line-height: 44px;
						margin-bottom: 15px;
						@include media-breakpoint-down(lg) {
							font-size: 18px;
							line-height: 22px;
							margin-bottom: 8px;
						}
						@include media-breakpoint-down(md) {
							font-size: 16px;
							line-height: 20px;
							margin-bottom: 6px;
						}
					}
					.expert-info {
						img {
							top: -10px;
							left: -45px;
							@include media-breakpoint-down(xl) {
								top: -20px;
							}
							@include media-breakpoint-down(lg) {
								width: 50px;
								height: auto;
							}
							@include media-breakpoint-down(md) {
								width: 38px;
								left: -21px;
							}
						}
						span {
							font-size: 25px;
							@include media-breakpoint-down(lg) {
								font-size: 12px;
							}
							@include media-breakpoint-down(md) {
								font-size: 11px;
							}
							&:first-of-type {
								margin-left: 35px;
								@include media-breakpoint-down(lg) {
									margin-left: 20px;
								}
								&::before {
									width: 25px;
									height: 3px;
									top: 17px;
									left: -33px;
									@include media-breakpoint-down(lg) {
										width: 16px;
										height: 2px;
										top: 8px;
										left: -20px;
									}
								}
							}
							&:last-of-type {
								margin-left: 26px;
								@include media-breakpoint-down(lg) {
									margin-left: 17px;
								}
								@include media-breakpoint-down(md) {
									margin-left: 17px;
								}
								&::before {
									width: 6px;
									height: 6px;
									top: 17px;
									left: -16px;
									@include media-breakpoint-down(lg) {
										width: 3px;
										height: 3px;
										top: 8px;
										left: -10px;
									}
								}
							}
						}
					}
				}
			}
		}
		.step-3 {
			@include media-breakpoint-up(sm) {
				min-height: 627px;
			}

			.image {
				svg {
					width: 100%;
					@include media-breakpoint-down(lg) {
						width: 66%;
						height: auto;
						margin-top: -35px;
						transform: translateX(235px);
					}
					@include media-breakpoint-down(md) {
						width: auto;
						height: 308px;
						transform: translateX(0px);
					}
				}
				.highlight-container {
					position: relative;
					top: -346px;
					left: 255px;
					@include media-breakpoint-down(xxl) {
						left: 203px;
					}
					@include media-breakpoint-down(xl) {
						left: 78px;
						transform: scale(0.7);
					}
					@include media-breakpoint-down(lg) {
						top: -252px;
						left: 283px;
						transform: scale(0.75);
					}
					@include media-breakpoint-down(md) {
						transform: scale(0.55);
						left: 4px;
						top: -198px;
					}
					@include media-breakpoint-down(sm) {
						transform: scale(0.5);
						left: 36px;
					}
					img {
						position: absolute;
						&.highlight {
							height: 115px;
							width: auto;
						}
						&.headshot {
							height: 80px;
							width: 80px;
							top: 17px;
							left: 20px;
						}
					}
					.text-container {
						top: 30px;
						right: -115px;
						width: 220px;
						sup {
							top: -5px;
							font-size: 4.5px;
						}
						p {
							font-size: 9px;
							line-height: 12px;
							margin-bottom: 4px;
						}
						.expert-info {
							img {
								width: 23px;
								height: auto;
								top: -4px;
								left: -12px;
							}
							span {
								font-size: 7px;
								&:first-of-type {
									margin-left: 12px;
									&::before {
										width: 8px;
										height: 1px;
										top: 5px;
										left: -12px;
									}
								}
								&:last-of-type {
									margin-left: 8px;
									&::before {
										width: 2px;
										height: 2px;
										top: 5px;
										left: -5px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	section.categories {
		.section-heading {
			.line {
				width: 877px;
				@include media-breakpoint-down(xl) {
					width: 706px;
				}
				@include media-breakpoint-down(lg) {
					width: 550px;
				}
				@include media-breakpoint-down(md) {
					left: 135px;
				}
			}
		}
		margin-bottom: 130px;
		@include media-breakpoint-down(md) {
			margin-bottom: 110px;
		}
		.categories-container {
			width: 100%;
			display: grid;
			grid-template-rows: auto auto;
			grid-template-columns: repeat(4, 1fr);
			align-items: stretch;
			gap: 34px;
			margin: 60px 0 0 0;
			@include media-breakpoint-down(lg) { display: none; }
		}

		.categories-slider {
			margin: 50px 0 124px;
		}

		.slider-pagination {
			bottom: 58px;
			display: none;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}

		.section-cta {
			background-color: #F6F6F6;
			border-radius: 25px;
			.title {
				h5 {
					span {
						display: block;
						background-color: $se-purple;
						padding: 0px 8px;
						margin-left: -10px;
						width: fit-content;
					}
				}
			}
			.link-with-circle {
				@include media-breakpoint-down(md) {
					margin: 22px 0 0 0;
				}
			}
		}
	}

	section.categories-cards-container {
		margin-bottom: 60px;
	}

	section.cta {
		background-color: #AAFF3F;
	}

	section.case-study {
		margin-bottom: 132px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 110px;
		}
		.section-heading {
			.line {
				width: 920px;
				@include media-breakpoint-down(xl) {
					width: 743px;
				}
				@include media-breakpoint-down(lg) {
					width: 572px;
				}
				@include media-breakpoint-down(md) {
					left: 113px;
				}
			}
		}
		.case-study-container {
			.details {
				margin-top: 40px;
				display: flex;
				@include  media-breakpoint-down(lg) {
					margin-top: 25px;
					flex-direction: column;
				}
				.overview,.results {
					overflow: hidden;
					flex-basis: 50%;
					h6 {
						margin-bottom: 25px;
						position: relative;
						@include media-breakpoint-down(lg) {
							overflow: hidden;
						}
						@include media-breakpoint-down(md) {
							margin-bottom: 17px;
						}
						&::after {
							content: "";
							position: absolute;
							top: 0px;
							height: 1px;
							background-color: #4B525E4A;
						}
					}
				}
				.overview {
					margin-right: 43px;
					@include media-breakpoint-down(lg) {
						margin-bottom: 27px;
						padding-right: 0px;
					}
					h6::after {
						width: 100%;
						right: -130px;
						@include media-breakpoint-down(xl) {
							width: 228px;
						}
						@include media-breakpoint-down(lg) {
							width: 100%;
							right: unset;
							left: 114px;
						}
					}
					p { margin-bottom: 16px; }
					ul { li {  &::marker { color: #038F3B; } } }
				}
				.results {
					padding-left: 43px;
					@include media-breakpoint-down(lg) {
						padding-left: 0px;
					}
					h6::after {
						width: 100%;
						right: -130px;
						@include media-breakpoint-down(xl) {
							width: 298px;
						}
						@include media-breakpoint-down(lg) {
							width: 100%;
							right: unset;
							left: 89px;
						}
					}
					.statistics-container {
						display: flex;
						justify-content: space-between;
						@include media-breakpoint-down(lg) {
							justify-content: flex-start;
						}
						@include media-breakpoint-down(md) {
							flex-direction: column;
						}
						.statistic {
							width: 195px;
							&.statistic-2 {
								@include media-breakpoint-only(md) {
									margin-left: 55px;
								}
							}
							p { 
								font-weight: $font-medium;
								margin: 5px 0 26px 0;
								@include media-breakpoint-down(lg) {
									margin: 3px 0 20px 0;
								}
							}
							span {
								font-size: 65px;
								font-weight: $font-bold;
								line-height: 50px;
								@include media-breakpoint-down(lg) {
									font-size: 55px;
									line-height: 50px;
								}
								sub {
									font-size: 34px;
									font-weight: inherit;
									line-height: inherit;
									bottom: 0;
									@include media-breakpoint-down(lg) {
										font-size: 28px;
									}
								}
							}
						}
					}
					.affiliates-container {
						p { margin-bottom: 16px; }
						.affiliates {
							display: flex;
							column-gap: 15px;
							.company {
								background-color: white;
								display: grid;
								place-content: center;
								padding: 14px;
								border-radius: 9px;
								border: 1px solid #E6E6E6;
								@include media-breakpoint-down(lg) {
									padding: 14px 16px;
								}
								@include media-breakpoint-down(md) {
									padding: 10px 14px;
								}
								img {
									height: 20px;
									width: auto;
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
		.section-cta {
			.title, .content { 
				padding-bottom: 0px;
			}
			.title {
				padding-top: 0px;
				@include media-breakpoint-down(lg) {
					padding: 50px 0px 0px 34px;
				}
				@include media-breakpoint-down(md) {
					padding: 50px 0px 0px 45px;
				}
				@include media-breakpoint-down(sm) {
					padding: 50px 0px 0px 0px;
				}
			}
			.content {
				padding-top: 0px;
				@include media-breakpoint-down(lg) {
					padding: 18px 0px 0px 34px;
				}
				@include media-breakpoint-down(md) {
					padding: 18px 0px 0px 45px;
				}
				@include media-breakpoint-down(sm) {
					padding: 18px 0px 0px 0px;
				}
			}
			h5 {
				span {
					font-style: italic;
					@include media-breakpoint-down(xl) {
						display: block;
					}
					@include media-breakpoint-down(md) {
						font-style: normal;
					}
				}
			}
			p { margin-bottom: 0px; }
		}
	}

	section.case-study-card-section {
		margin-bottom: 50px;
	}

	section.newsletter {
		.newsletter-container {
			padding: 92px 0 117px 0;
			flex-direction: column;
			align-items: center;
		}
	}
}