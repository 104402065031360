.lightbox {
	margin-top: 100px;
	.lb-container {
		.lb-image { border: none; }
		.lb-nav {
			a.lb-custom-arrow {
				background-image: url(../../public/images/slider_arrow.svg);
				width: 60px;
				height: 60px;
				background-color: white;
				position: absolute;
				opacity: 1;
				border-radius: 50vw;
				background-size: 13px;
				bottom: -100px;
				border: 2px solid $font-color-primary;
				background-position: 50%;
				&.lb-prev { 
					left: unset;
					right: calc(50% + 40px);
					transform: scale(-1, 1) translateX(-50%);
				}
				&.lb-next {
					left: calc(50% + 40px);
					right: unset;
					transform: translateX(-50%);
				}
			}
		}
	}

	.lb-data {
		.lb-close {
			background-image: url(../../public/images/modal_close.svg);
			background-color: $font-color-primary;
			background-position: 50%;
			width: 65px;
			height: 65px;
			position: relative;
			top: -675px;
			right: 0px;
			border: none;
			border-radius: 50vw;
			opacity: 1;
		}
	}

	.lb-details {
		display: none;
	}
}