// Modals - https://getbootstrap.com/docs/4.1/components/modal/
//-----------------------------------------------------------------------------------------------------//

.modal {
	.modal-dialog {
		top: 15%;
	}
	.modal-content {
		padding: 56px 70px 72px;
		border-radius: 30px;
		.modal-header {
			border: none;
			padding: 0;
			h2 {
				font-size: 46px;
				font-weight: $font-regular;
				line-height: 60px;
				margin-bottom: 22px;
				@include media-breakpoint-down(lg) {
					font-size: 32px;
					line-height: 45px;
				}
				span {
					font-family: $font-secondary;
					font-style: italic;
					font-weight: $font-medium;
					display: block;
				}
			}
			.section-lead {
				margin: 22px 0 35px;
				@include media-breakpoint-down(md) {
					margin: 18px 0 35px;
				}
			}
			.btn-close {
				top: -90px;
				right: 0px;
				transition: 0.2s all ease;
				img {
					filter: invert(0);
					transition: 0.2s all ease;
				}
				@include media-breakpoint-down(sm) {
					top: 23px;
					right: 23px;
					z-index: 1;
				}
				&:hover {
					background: white;
					img {
						filter: invert(1);
					}
				}
			}
		}
		.modal-body {
			padding: 0;
			overflow: hidden;
			h3 {
				font-size: 22px;
				font-weight: $font-bold;
				position: relative;
				display: flex;
				.line {
					margin-top: 26px;
					margin-left: 20px;
					border-top: 1px solid #4B525E4A;
					flex: 1;
					@include media-breakpoint-down(sm) {
						margin-top: 16px;
					}
				}
			}
			.form {
				margin-top: 20px;
				display: flex;
				flex-direction: column;
				input {
					&[type=checkbox] {
						width: 22px;
						height: 22px;
						border: 1px solid #767676;
						border-radius: 3px;
						margin-right: 12px;
						margin-top: 0px;
					}
				}
				label { 
					margin-top: 20px;
					margin-bottom: 0;
					display: flex;
				}
				button { 
					margin-top: 30px;
					padding: 17px 0; 
				}
			}
		}
	}
	&#modal-subscribe {
		@include media-breakpoint-down(sm) {
			width: 100%;
			height: 100%;
			.modal-dialog {
				top: 0px;
				margin: 0;
				height: inherit;
				.modal-content {
					height: inherit;
					border-radius: 0;
					padding: 60px 25px 0;
					.modal-header {
						.btn-close {
							right: 15px;
							top: 15px;
						}
					}
				}
			}
		}
	}

	&#brands-modal-subscribe {
		.modal-body {
			p {
				font-size: 20px;
				line-height: 34px;
			}
		}
		.modal-content {
			@include media-breakpoint-down(sm) {
				border-radius: 0px;
				width: 100vw;
				min-height: 100vh;
				position: absolute;
				top: -16vh;
				left: -8px;
				padding: 89px 25px 83px 25px;
			}
		}
		.form {
			margin-top: 20px;
			.form-input:first-child {
				margin-bottom: 35px;
			}
			.mdc-text-field__input {
				margin-top: 0px;
			}
			.mdc-select {
				width: 100%;
				label {
					margin-top: 0px;
				}
			}
		}
	}

  	&#publishers-lead-modal {
		.wizard-steps {
	  		display: flex;
			align-items: center;
			padding: 0;
			margin-bottom: 25px;
			li {
				list-style: none;
				position: relative;
				margin-right: 100px;
				margin-bottom: 0px;
				@include media-breakpoint-down(sm) {
					margin-right: 65px;
				}
				&:not(:last-child):after {
					content: "";
					position: absolute;
					top: 15px;
					left: 30px;
					width: 80px;
					height: 2px;
					background-color: #ddd; 
					transform: translateY(-50%);
					z-index: -1;  
					@include media-breakpoint-down(sm) {
						width: 46px;
						z-index: 1;
					}
				}
				&.completed:after {
					background-color: #038F3B; 
				}
				&.inactive {
					width: 20px;
					height: 20px;
					border: 1px solid #DEDEDE;
					border-radius: 50%;
					margin-top: 3px;
				}
				&.inactive:after {
					top: 8px;
				}
			}
		}
		.modal-dialog {
			@include media-breakpoint-down(sm) {
			top: -10px;
			}
		}
		.modal-content {
			@include media-breakpoint-up(md) {
				width: 752px;
				transform: translate(-110px, -60px);
			}
			@include media-breakpoint-down(sm) {
				border-radius: 0px;
				width: 100vw;
				min-height: 100vh;
				position: absolute;
				top: -25px;
				left: -10px;
				padding: 89px 15px 83px 15px;
			}
			.modal-header {
				.modal-title {
					font-size: 32px;
					line-height: 45px;
					@include media-breakpoint-down(sm) { margin-bottom: 15px; }
				}
				.btn-close {
					width: 30px;
					height: 30px;
					@include media-breakpoint-down(sm) {
					  right: 20px;
					  top: 20px;
					}
				}
			}
			.row { margin-bottom: 30px; }
			.form {
				margin-top: 5px;
				label { font-size: 18px; }
				.form-input {
					p {
						@include media-breakpoint-down(sm) {
							font-size: 18px;
							line-height: 28px;
						}
					}
				}
			}
			.yes-no-radio-container {
				display: flex;
				.radio-container {
					display: flex;
					padding: 18px;
					width: 147px;
					height: 56px;
					border: 1px solid rgba(207, 208, 212, 1);
					align-items: center;
					cursor: pointer;
					&:has(> input:checked) {
						border: 1px solid rgba(18, 26, 43, 1);
						background-color: rgba(231, 232, 233, 1);
					}
					&:nth-child(1) { border-radius: 10px 0px 0px 10px; }
					&:nth-child(2) {
						border-radius: 0px 10px 10px 0px;
						border-left: none
					}
					input {
						width: 20px; 
						margin-right: 20px;
					}
					span {
						font-size: 18px;
						font-weight: $font-medium;
					}
				}
			}
			.modal-body {
				.form {
					button {
						margin-top: 60px;
					}
				}
			  	.failed-image-container {
					width: 100%;
				  	border-radius: 21px;
				  	overflow: hidden;
				  	height: 290px;
				  	img {
						width: 100%;
						object-fit: contain;
						object-position: center center;
					}
				}
  
				.failed-description {
					margin-top: 25px;
					font-size: 20px;
				}
  
				.failed-reasons-list {
					margin-top: 16px;
					font-size: 20px;
					padding: 0px;
					li {
						list-style: none;
						font-size: 20px;
						display: flex;
						align-items: flex-start;
						img {
							margin-right: 10px;
							margin-top: 5px;
						}
					}	
				}
				.prev-next-container {
					display: flex;
					justify-content: space-between;
					width: 100%;
					.btn-outline {
						width: 35%;
					}
					.btn-primary {
						width: 60%;
					}
				}
				.buttons-container {
					display: flex;
					align-items: center;
					margin-top: 30px;
					@include media-breakpoint-down(sm) { display: block; }
					.btn-primary {
						border-radius: 36px;
						margin-right: 20px;
						padding-left: 64px;
						padding-right: 64px;
						@include media-breakpoint-down(sm) { width: 100%; }
					}
					.link-with-circle {
						margin-top: 0px;
						@include media-breakpoint-down(sm) {
							margin: auto;
							margin-top: 20px;
						}
					}
				}
				p {
					font-size: 18px;
					line-height: 28px;
				} 
				h3 {
				  	@include media-breakpoint-down(sm) {
						font-size: 20px;
						font-weight: $font-medium;
						line-height: 30px;
						margin-top: 30px;
					}
				}
				.mdc-select {
					margin-top: 35px;
					width: 100%;
				}
  
				.stat-container {
					border-radius: 25px;
					background-color: #31353B;
					margin-top: 33px;
					margin-bottom: 59px;
					color: #fff;
					@include media-breakpoint-down(sm) {
						margin-top: 25px;
						overflow: hidden;
					}
					.rating-meter-container {
						display: flex;
						padding: 30px 40px 40px 40px;
						border-bottom: 1px solid #474A50;
						@include media-breakpoint-down(sm) {
							display: block;
							padding: 23px 25px 36px 25px;
						}
						.rating-value-container {
							margin-right: 20px;
							@include media-breakpoint-down(lg) { margin-right: 68px; }
							@include media-breakpoint-down(sm) {
								margin-right: 0px;
								text-align: center;
								margin-bottom: 20px;
							}
							.rating-title {
								font-size: 18px;
								font-weight: $font-medium;
								color: #fff;
								@include media-breakpoint-down(lg) { font-size: 16px; }
							}
							.rating-value {
								font-size: 65px;
								font-weight: $font-bold;
								@include media-breakpoint-down(lg) { font-size: 55px; }
							}
						}
						.meter-image-container {
							width: 388px;
							@include media-breakpoint-down(lg) { width: 325px; }
							@include media-breakpoint-down(sm) { width: 100% }
							img { width: 100%; }
						}
					}
  
					.traffic-container {
						display: flex;
						@include media-breakpoint-down(sm) { display: block; }
						.traffic-title {
							font-size: 18px;
							font-weight: $font-medium;
							color: #fff;
							@include media-breakpoint-down(lg) { font-size: 16px; }
						}
						.traffic-stat-container {
							padding: 30px 40px 40px 40px;
							@include media-breakpoint-down(sm) { border-bottom: 1px solid #474A50; }
							.traffic-title {
								@include media-breakpoint-down(sm) { text-align: center; }
							}
							.traffic-value {
								p {
									font-size: 65px;
									font-weight: $font-bold;
									color: #fff;
									line-height: 50px;
									margin-top: 15px;
									@include media-breakpoint-down(lg) { font-size: 55px; }
									@include media-breakpoint-down(sm) {
										text-align: center;
										margin-top: 5px;
									}
								}
							}
						}
						.traffic-image-container {
							padding: 30px 40px 40px 25px;
							flex: 1;
							background-size: cover;
							background-image: url(../../public/images/grid.svg);
							@include media-breakpoint-down(sm) { padding: 37px 44px 46px 44px; }
							.traffic-title { text-align: left; }
							img { margin-top: -28px; }
						}
					}
				}
  
				.counter-container {
					margin: 20px 0 5px;
					input {
						width: 147px;
					}
				}		  
			}
		}
	}
	&#pricing-modal-plans {
		left: 50%;
		transform: translateX(-50%);
		@include media-breakpoint-down(sm) {
			left: 0;
			transform: translateX(0);
		}
		.modal-dialog {
			top: 10%;
			@include media-breakpoint-only(md) {
				max-width: 720px;
			}
			@include media-breakpoint-down(sm) {
				top: 0px;
				margin: 0;
				height: 100vh;
			}
			// max-width: 1140px !important;
			.modal-content {
				justify-content: center;
				flex-direction: row;
				padding: 0;
				@include media-breakpoint-down(md) {
					flex-direction: column;
				}
				@include media-breakpoint-down(sm) {
					border-radius: 0;
					height: 100%;
				}
				& > div {
					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}
				.modal-header {
					flex-direction: column;
					align-items: flex-start;
					.section-heading {
						margin-bottom: 0px;
					}
					.section-lead {
						margin: 22px 0 35px;
					}
					.btn-close {
						right: 7px;
						@include media-breakpoint-down(sm) {
							top: 15px;
							right: 15px;
						}
					}
				}
				.content-left-container,.content-right-container {
					padding: 56px 70px 70px;
					@include media-breakpoint-down(lg) {
						padding: 25px 25px 60px;
					}
				}
				.content-left {
					.modal-body {
						.plan-container {
							background-color: #F0FFB8;
							border-radius: 25px;
							padding: 20px 30px 24px;
							.header {
								position: relative;
								display: flex;
								justify-content: space-between;
								margin-bottom: 40px;
								&::after {
									content: "";
									position: absolute;
									width: calc(100% + 60px);
									bottom: -26px;
									left: -30px;
									height: 1px;
									background-color: #121a2b14;
									@include media-breakpoint-down(lg) {
										bottom: -18px;
									}
								}
								p.heading {
									font-size: 43px;
									font-weight: $font-medium;
									line-height: 50px;
									@include media-breakpoint-down(lg) {
										font-size: 30px;
									}
								}
								p.price {
									font-size: 45px;
									font-weight: $font-bold;
									line-height: 45px;
									@include media-breakpoint-down(lg) {
										font-size: 40px;
									}
									span {
										font-size: 18px;
										line-height: 18px;
									}
								}
							}
							.body {
								.feature-container {
									display: flex;
									justify-content: space-between;
									margin-bottom: 10px;
									&:last-child { margin-bottom: 0px; }
									p {
										font-weight: $font-medium;
										color: #343348;
										text-wrap: nowrap;
										&.feature-title {
											text-wrap: wrap;
										}
									}
								}
							}
							.footer {
								display: flex;
								position: relative;
								margin-top: 36px;
								&::before {
									content: "";
									position: absolute;
									width: calc(100% + 60px);
									top: -12px;
									left: -30px;
									height: 1px;
									background-color: #121a2b14;
								}
								a {
									text-align: center;
									font-size: 20px;
									font-weight: $font-medium;
									line-height: 28px;
									text-decoration: underline;
									margin: 0 auto;
								}
							}
						}
					}
					.modal-footer {
						padding: 0;
						border: none;
						button {
							width: 270px;
							margin: 0 auto;
							margin-top: 30px;
						}
					}
				}
				.content-right-container {
					background-color: #F6F6F6;
					border-radius: 0 30px 30px 0;
					@include media-breakpoint-down(md) {
						border-radius: 0 0 30px 30px;
					}
				}
				.content-right {
					form {
						display: flex;
						flex-direction: column;
						.form-notice {
							font-size: 20px;
							font-weight: $font-medium;
							line-height: 34px;
							margin-bottom: 35px;
							@include media-breakpoint-down(lg) {
								font-size: 18px;
								line-height: 30px;
							}
						}
						button {
							width: 170px;
							height: 56px;
							justify-self: flex-start;
							margin-top: 30px;
							@include media-breakpoint-down(md) {
								width: 210px;
								margin: 25px auto 0;
							}
						}
					}
				}
			}
		}
	}
	&#pricing-plans-suggest-topics {
		left: 50%;
		transform: translateX(-50%);
		.btn-close { right: 8px; }
		.modal-content {
			padding: 0;
			background: transparent;
			border: none;
		}
		iframe {
			width: 100%;
			height: auto;
			aspect-ratio: 16/9;
		}
	}
}

.modal-open .container-fluid, .modal-open  .container { filter: blur(3px); }
