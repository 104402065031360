section.cta {
	padding: 40px 0;
	margin-bottom: 100px;
	overflow: hidden;
	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 20px;
		padding-bottom: 38px;
	}

	.cta-container, .cta-container-sm {
		display: flex;
		align-items: center;
		column-gap: 40px;

		.cta-item {
			display: flex;
			align-items: center;
			flex-shrink: 0;

			p {
				font-size: 34px;
				font-weight: $font-medium;
				@include media-breakpoint-down(md) {
					font-size: 28px;
				}
			}

			div {
				width: 60px;
				height: 60px;
				display: grid;
				place-content: center;
				margin-left: 16px;
				background-color: $font-color-primary;
				transition: 0.25s background-color;
				@include media-breakpoint-down(md) {
					width: 50px;
					height: 50px;
				}
				img {  width: 24px; }
			}

			&:hover, &:active {
				div {
					background-color: transparent;
					border: 2px solid $font-color-primary;
					img { filter: invert(1); }
				}
			}
		}
	}

	.cta-container-sm {
		justify-content: center;
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	.cta-container {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}