.nav-tabs {
	border: none;
	justify-content: space-between;
	flex-wrap: nowrap;
	.nav-tab {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 143px;
		height: auto;
		padding: 24px 0;
		border: none;
		border-radius: 15px;
		background-color: white;
		color: #121A2B;
		font-size: 16px;
		font-weight: $font-medium;
		line-height: 1.5;
		//the below property forces each word to be on a new line
		word-spacing: 100vw;

		&:last-child {
		  margin-right: 0px;
		}

		&:hover{  background-color: #121A2B0D; }
		&.active, &:active { background-color: #121a2b14; }
		img {
		  height: 45px;
		  margin-bottom: 10px;
		  @include media-breakpoint-down(sm) {
			margin-right: 8px;
			margin-bottom: 0px;
		  }
		}

		@include media-breakpoint-only(md) {
			  padding: 24px 24px;
		}

        @include media-breakpoint-down(sm) {
          flex-direction: row;
          font-size: 14px;
          width: fit-content;
          min-width: 155px;
          height: fit-content;
          padding: 15px 15px;
          font-weight: $font-medium;
          text-align: left;
        }
    }
}

.tab-content {
	margin-top: 45px;
  @include media-breakpoint-down(lg) {
	margin-top: 24px;
  }
	.tab-pane {
		border-radius: 35px;
		padding: 55px 95px 0;
	@include media-breakpoint-down(lg) {
	  padding: 40px 25px 0;
	}
	background-color: #E0FAFF;

		&.tab-pane-1 { background-color: #E0FAFF; }

		.tab-header {
			display: flex;
			padding-bottom: 45px;
	  @include media-breakpoint-down(lg) {
		display: block;
		padding-bottom: 35px;
	  }

			p {
				margin-top: 12px;
				width: 60%;
				padding-left: 14px;
		margin-right: 45px;
				&.heading {
					font-size: 35px;
					font-weight: $font-medium;
					line-height: 50px;
					margin-top: 0px;
					width: 40%;
					padding-left: 45px;
		  padding-right: 24px;
		  margin-right: 0px;
		  @include media-breakpoint-down(lg) {
			font-size: 25px;
			line-height: 35px;
			font-weight: $font-medium;
			width: auto;
			padding-left: 0px;
		  }
				}
		@include media-breakpoint-down(lg) {
		  width: auto;
		  padding-left: 0px;
		  margin-top: 14px;
		}
			}
		}

		img {
			width: 100%;
			height: auto;
		}
	}
}
