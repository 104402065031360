section.newsletter {
    margin-bottom: 100px;
    @include media-breakpoint-down(md) {
        margin-bottom: 90px;
    }
    .newsletter-card-container {
        margin: auto;
        background-color: #191D24;
        border-radius: 35px;
        position: relative;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            width: 93vw;
            max-width: 1440px;
        }
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
    .newsletter-container {
        display: flex;
        text-align: center;
        @include media-breakpoint-down(xl) {
            width: calc(100% + 120px);
            left: -60px;
            padding: 50px 0 80px;
        }
        @include media-breakpoint-down(lg) {
            width: 100%;
            left: 0px;
        }
        @include media-breakpoint-down(md) {
            padding: 50px 40px 80px;
            width: calc(100% + 30px);
            left: -15px;
        }
        h2,p {
            padding: 0 236px;
            @include media-breakpoint-down(md) {
                padding: 0px
            }
        }
        h2 {
            color: white;
            margin-bottom: 22px;
            @include media-breakpoint-down(lg) {
                padding: 0;
            }
            @include media-breakpoint-down(md) {
                margin-bottom: 16px;
            }
        }
        p {
            color: #EBEBEB;
            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 28px;
                padding: 0 120px;
            }
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
        button {
          position: relative;
          z-index: 1;
        }
        img { 
            position: absolute;
            z-index: 0;
            &.left {
                left: 0;
                top: 0;
                @include media-breakpoint-down(lg) {
                    width: 25%;
                }
                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
            }
            &.right {
                right: 0;
                bottom: 0;
                @include media-breakpoint-down(lg) {
                    width: 160px;
                    bottom: -6px;
                }
                @include media-breakpoint-down(sm) {
                    width: 160px;
                    bottom: -6px;
                }
            }
        }
        .form {
            margin-top: 55px;
            display: flex;
            column-gap: 28px;
            z-index: 10;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                margin-top: 45px;
                width: 330px;
            }
            @include media-breakpoint-down(md) {
                margin-top: 40px;
                width: 100%;
            }
            .custom-input {
                height: 56px;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                    margin-bottom: 25px;
                }
                @include media-breakpoint-down(md) {
                    max-width: 300px;
                }
            }
            input { &::-webkit-calendar-picker-indicator { display: none !important; } }
            datalist {
                position: absolute;
            }
            button {
                width: 176px;
                margin-left: 8px;
                z-index: 1;
                height: 56px;
                @include media-breakpoint-down(lg) {
                    margin: 0 auto;
                }
            }
        }
    }
}
