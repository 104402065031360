// Cards - https://getbootstrap.com/docs/4.1/components/card/
//-----------------------------------------------------------------------------------------------------//\
.card {
	.card-header {
		background-color: transparent;
		border: none;
		padding: 0;
		.card-heading {
			font-size: 26px;
			line-height: 34px;
			font-weight: $font-bold;
			@include media-breakpoint-down(md) {
				font-size: 22px;
				line-height: 28px;
			}
			&.large {
				font-size: 35px;
				line-height: 50px;
				font-weight: $font-medium;
				@include media-breakpoint-down(lg) {
					font-size: 25px;
					line-height: 35px;
				}
			}
		}
	}
	.card-body {
		padding: 0;
	}
	&.card-pill {
		position: absolute;
		border: 1px solid #f4f4f4;
		border-radius: 20px;
		background-color: white;
		box-shadow: 1px 2px 4px #0000001a;
		opacity: 0.5 !important; // @TODO: see if we can update animation for these to remove the opacity transition or else we will need the !important
		transition: 0.2s opacity ease;
		&:hover {
			opacity: 1 !important;
		}
		p {
			margin: 0;
		}
		.card-header {
			padding: 14px 20px 12px 20px;
			border-radius: 20px 20px 0 0;
			p {
				color: #343348;
				font-size: 13px;
				line-height: 13px;
			}
		}
		.card-body {
			padding: 0px 20px 15px;
			.pill-container {
				display: flex;
				column-gap: 8px;
				p {
					font-size: 14px;
					font-weight: $font-medium;
				}
				.logo-pill {
					background-color: #f3f3f3;
					width: 30px;
					display: grid;
					place-content: center;
					img {
						width: 100%;
					}
				}
				.dr-pill {
					padding: 1px 12px;
					background-color: #dff8e1;
				}
				.traffic-pill {
					padding: 1px 12px;
					background-color: #ecf4fd;
				}
			}
		}
		.card-footer {
			background-color: #f6f6f6;
			border: 1px solid #f4f4f4;
			border-radius: 0 0 20px 20px;
			padding: 0px;
			p {
				font-size: 13px;
				font-weight: $font-medium;
				text-align: center;
			}
		}
	}

	&.card-testimony {
		border: 1px solid #f6f6f6;
		border-radius: 35px;
		box-shadow: 1px 2px 4px #232b2d1a;
		padding: 45px 50px 60px 50px;
		margin: 0 30px;
		@include media-breakpoint-down(lg) {
			padding: 35px 25px 60px;
		}
		.card-header {
			column-gap: 20px;
			padding: 0;
			margin-bottom: 25px;
			@include media-breakpoint-down(lg) {
				margin-bottom: 14px;
			}
			img {
				width: 70px;
				height: auto;
			}
			p {
				font-size: 22px;
				font-weight: $font-medium;
				line-height: 25px;
				@include media-breakpoint-down(lg) {
					font-size: 18px;
				}
			}
			p:first-of-type {
				margin-bottom: 10px;
				font-weight: $font-bold;
				@include media-breakpoint-down(lg) {
					margin-bottom: 4px;
				}
			}
			p:last-of-type {
				color: #494949;
			}
			svg {
				position: absolute;
				top: 30px;
				right: 30px;
				z-index: -1;
			}
		}
		.card-body {
			padding: 0;
			p {
				font-size: 24px;
				font-weight: $font-medium;
				line-height: 38px;
				@include media-breakpoint-down(lg) {
					font-size: 20px;
					line-height: 34px;
				}
			}
		}
	}

	&.card-full {
		border: none;
		&:not(:last-child) {
			margin-bottom: 30px;
		}
		&.step-1 {
			background: linear-gradient(270deg, #f0fbf0, #f0fec4);
			h3 {
				@include media-breakpoint-only(md) {
					width: 40%;
				}
				sup {
					color: #038f3b;
				}
			}
			border-radius: 35px 35px 35px 35px;
			.company-card-cloud {
				@include media-breakpoint-down(lg) {
					height: 220px;
				}
			}
		}
		&.step-2 {
			background: linear-gradient(270deg, #f5e8ff, #deebfb);
			h3 {
				sup {
					color: #7d0d99;
				}
			}
			border-radius: 35px 35px 35px 35px;
		}
		&.step-3 {
			background: linear-gradient(90deg, #e0faff, #ecfdef);
			h3 {
				sup {
					color: #005cd9;
				}
			}
			border-radius: 35px 35px 35px 35px;
		}
		.content {
			padding: 85px 0 119px 70px;
			@include media-breakpoint-down(xl) {
				padding: 113px 0 80px 70px;
			}
			@include media-breakpoint-down(lg) {
				padding: 62px 40px 35px;
			}
			@include media-breakpoint-down(md) {
				padding: 58px 25px 35px;
			}
			@include media-breakpoint-down(sm) {
				padding: 58px 25px 40px;
			}
			.card-header {
				padding: 0;
				h3 { 
					margin-bottom: 18px; 
					@include media-breakpoint-down(lg) {
						margin-bottom: 14px;
					}
				}
			}
			.card-body {
				padding: 0;
				@include media-breakpoint-only(md) {
					width: 85%;
				}
				p { 
					margin-bottom: 16px;
					@include media-breakpoint-down(lg) {
						margin-bottom: 12px;
					}
				}
				ul {
					font-size: 18px;
					font-weight: $font-regular;
					line-height: 28px;
					margin-bottom: 0px;
				}
			}
		}
	}

	&.company-card {
		position: absolute;
		padding: 15px 18px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: white;
		border: 1px solid #f4f4f4;
		border-radius: 20px;
		box-shadow: 1px 2px 4px #0000001a;
		width: 240px;
		@include media-breakpoint-down(lg) {
			width: 196px;
			padding: 12px 12px;
		}
		p {
			font-size: 14px;
			font-weight: $font-medium;
			@include media-breakpoint-down(lg) {
				font-size: 11px;
				line-height: 24px;
			}
		}
		.logo-pill {
			background-color: #f3f3f3;
			width: 30px;
			height: 30px;
			display: grid;
			place-content: center;
			@include media-breakpoint-down(lg) {
				width: 24px;
				height: 24px;
				img {
					width: 24px;
					height: auto;
				}
			}
		}
		.dr-pill {
			height: 30px;
			padding: 0px 8px;
			background-color: #dff8e1;
			@include media-breakpoint-down(lg) {
				height: 24px;
				padding: 0px 9px;
			}
		}
		.traffic-pill {
			height: 30px;
			padding: 0px 8px;
			background-color: #ecf4fd;
			@include media-breakpoint-down(lg) {
				height: 24px;
				padding: 0px 9px;
			}
		}
	}

	&.card-category {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 30px 15px 15px 30px;
		border: 1px solid #f6f6f6;
		border-radius: 25px;
		box-shadow: 1px 2px 4px #232b2d1a;
		img {
			width: 60px;
			height: 60px;
			margin-bottom: 16px;
			@include media-breakpoint-down(lg) {
				width: 50px;
				height: 50px;
				margin-bottom: 10px;
			}
		}
		h4 {
			margin-bottom: 7px;
			@include media-breakpoint-down(lg) {
				margin-bottom: 3px;
			}
		}
		p {
			font-size: 20px;
			line-height: 34px;
			font-weight: $font-medium;
			margin-bottom: 6px;
			@include media-breakpoint-down(lg) {
				font-size: 18px;
				margin-bottom: 2px;
			}
		}
	}

	&.card-success {
		border: 1px solid #f6f6f6;
		border-radius: 25px;
		padding: 40px 50px 50px;
		@include media-breakpoint-down(sm) {
			padding: 28px 25px 35px 25px;
		}

		&:nth-child(1) {
			@include media-breakpoint-down(sm) {
				margin-bottom: 25px;
			}
		}

		box-shadow: 1px 2px 4px #232b2d1a;
		overflow: hidden;
		img.blob {
			position: absolute;
			height: 105%;
			width: auto;
			z-index: 1;
			&.blob-green {
				top: -5px;
				right: -1px;
			}
			&.blob-purple {
				top: 0px;
				right: -1px;
				@include media-breakpoint-down(lg) {
					left: -151px;
				}
			}
		}
		.card-body {
			padding: 0;
			z-index: 2;
			p.card-title {
				font-size: 22px;
				line-height: 22px;
				font-weight: $font-bold;
				margin-bottom: 35px;
				@include media-breakpoint-down(sm) {
					font-size: 16px;
					margin-bottom: 30px;
				}
				&.green {
					color: #038f3b;
				}
				&.purple {
					color: #7d0d99;
				}
			}
			.bullet-group {
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0px;
				}

				p.heading {
					font-size: 24px;
					@include media-breakpoint-down(sm) {
						font-size: 22px;
						line-height: 28px;
					}

					line-height: 34px;
					font-weight: $font-bold;
					color: #121a2bb3;
					margin-bottom: 10px;
				}

				p.description {
					font-size: 22px;
					line-height: 34px;
					font-weight: $font-bold;
				}

				.metrics {
					display: flex;
					@include media-breakpoint-down(lg) {
						display: block;
					}
					.metric {
						padding-right: 50px;
						&:last-child {
							@include media-breakpoint-up(lg) {
								padding-right: 0px;
							}
						}
						@include media-breakpoint-down(lg) {
							margin-bottom: 25px;
						}
						.stat {
							font-size: 45px;
							line-height: 50px;
							font-weight: $font-bold;
							span {
								font-size: 20px;
							}
						}
					}
				}

				.affiliates {
					display: flex;
					column-gap: 15px;
					margin-top: 20px;
					.company {
						background-color: white;
						display: grid;
						place-content: center;
						padding: 14px;
						border-radius: 9px;
						border: 1px solid #e6e6e6;
						@include media-breakpoint-down(lg) {
							padding: 14px 16px;
						}
						@include media-breakpoint-down(md) {
							padding: 10px 14px;
						}
						img {
							height: 25px;
							width: auto;
							margin: 0;
						}
					}
				}
			}
		}
	}

	&.card-transparent-bg {
		border: none;

		.card-img-top {
			width: 260px;
			height: 260px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			&.blue {
				background: linear-gradient(270deg, #f0fbf0, #f0fec4);
			}
			&.purple {
				background: #e1ebfc;
			}
			&.green {
				background: linear-gradient(90deg, #e0faff, #ecfdef);
			}
			img {
				width: 296px;
				height: auto;
			}
			.fit-width-img {
				width: 100%;
			}
		}
		.card-header {
			padding: 35px 0 0 0;
			@include media-breakpoint-down(lg) {
				padding: 50px 0 0 0;
			}
			h3 {
				font-size: 26px;
				line-height: 34px;
				font-weight: $font-bold;
				margin-bottom: 16px;
				text-align: center;
				@include media-breakpoint-down(lg) {
					font-size: 25px;
					line-height: 35px;
					margin-bottom: 12px;
				}
				sup {
					font-size: 18px;
					line-height: 22px;
					position: absolute;
					top: -43px;
					left: 50%;
					transform: translateX(-50%);
					&.green {
						color: #118845;
					}
					&.purple {
						color: #7d0d99;
					}
					&.blue {
						color: #005cd9;
					}
					@include media-breakpoint-down(lg) {
						font-size: 16px;
						line-height: 20px;
						top: -30px;
					}
				}
				// @TODO clean up this with sup
				span {
					font-size: 18px;
					line-height: 22px;
					display: block;
					margin-bottom: 22px;
					font-weight: $font-bold;
					&.green {
						color: #118845;
					}
					&.purple {
						color: #7d0d99;
					}
					&.blue {
						color: #005cd9;
					}
					@include media-breakpoint-down(lg) {
						font-size: 16px;
						line-height: 20px;
						top: -30px;
					}
				}
			}
		}
		.card-body {
			padding: 0 0 0 0;
			padding: 0;
			@include media-breakpoint-down(sm) {
				padding: 0px 30px 50px 30px;
			}
			text-align: center;
		}
	}

	&.card-product {
		border: none;
		border-radius: 25px;
		overflow: hidden;
		@include media-breakpoint-down(sm) {
			border-radius: 0px;
		}
		.image-container {
			padding: 45px 60px;
			border-radius: 25px;
			@include media-breakpoint-down(lg) {
				padding: 47px 50px 46px 50px;
			}
		}
		.card-header {
			display: flex;
			justify-content: space-between;
			padding: 25px 18px 18px;
			@include media-breakpoint-down(sm) {
				padding: 20px 15px 14px 15px;
			}
			p {
				font-size: 26px;
				line-height: 34px;
				font-weight: $font-bold;
				@include media-breakpoint-down(lg) {
					font-size: 22px;
					line-height: 28px;
				}
			}
		}
		.card-body {
			padding: 0 18px;
			@include media-breakpoint-down(sm) {
				padding: 0px 15px
			}
			p {
				font-size: 20px;
				line-height: 34px;
				font-weight: $font-medium;
				@include media-breakpoint-down(lg) {
					font-size: 18px;
				}
			}
		}
		&.product-1 {
			.image-container {
				background-color: #fff0f2;
			}
		}
		&.product-2 {
			.image-container {
				background-color: #e0faff;
			}
		}
		&.product-3 {
			.image-container {
				background-color: #f0ffb8;
			}
		}
		&.product-4 {
			.image-container {
				background-color: #f5e8ff;
			}
		}
		&.product-5 {
			.image-container {
				background-color: #dbffde;
			}
		}
		&.product-6 {
			.image-container {
				background-color: #e4f0ff;
			}
		}
	}

	&.card-benefits {
		background-color: #fafafa;
		padding: 40px 40px 45px;
		border: none;
		border-radius: 25px;
		height: 100%;
		@include media-breakpoint-down(md) {
			padding: 25px 24px 30px 30px;
		}
		.card-header {
			display: flex;
			flex-direction: column;
			padding: 0;
			img {
				height: 60px;
				width: 60px;
				margin-bottom: 18px;
				@include media-breakpoint-down(md) {
					height: 50px;
					width: 50px;
				}
			}
			.card-heading {
				margin-bottom: 15px;
				@include media-breakpoint-down(md) {
					margin-bottom: 12px;
				}
			}
		}
		.card-body {
			padding: 0;
			* {
				font-size: 20px;
				line-height: 28px;
				@include media-breakpoint-down(md) {
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
	}
	&.card-before-and-after {
		padding: 20px 25px 25px;
		position: relative;
		border: 1px solid #f6f6f66d;
		background: transparent;
		border-radius: 20px;
		width: 360px;
		overflow: hidden;
		box-shadow: 0px 2px 4px #4b525e1f;
		&::before {
			content: '';
			position: absolute;
			width: 120%;
			height: 120%;
			top: 0;
			left: 0;
			background-color: #ffffffb3;
			border-radius: 20px;
			filter: blur(3px);
			z-index: -1;
		}
		.card-header {
			display: flex;
			padding: 0;
			p {
				font-size: 22px;
				font-weight: $font-bold;
				line-height: 28px;
				@include media-breakpoint-down(lg) {
					font-size: 20px;
				}
			}
			img {
				margin-right: 10px;
			}
		}

		.card-body {
			padding: 14px 0 0 0;
		}

		&.card-before-and-after-mobile {
			padding: 30px 25px 30px;
			.card-top {
				position: relative;
				&::after {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: #121a2b30;
					bottom: -24px;
					left: 0;
				}
				.card-body {
					margin-bottom: 24px;
				}
			}
			.card-bottom {
				padding-top: 24px;
			}
		}
	}
	&.card-sidebar {
		padding: 40px 50px 50px;
		border-radius: 25px;
		border: none;
		&:first-child {
			margin-bottom: 40px;
			@include media-breakpoint-only(md) {
				margin-bottom: 0px;
			}
			@include media-breakpoint-down(md) {
				margin-top: 60px;
				margin-bottom: 25px;
			}
		}
		&.try-now { background: linear-gradient(229deg, #E0FAFFCC, #F5E8FFCC); }
		&.learn-more { background: linear-gradient(90deg, #E0FAFFCC, #ECFDEFCC); }
		.card-heading { margin-bottom: 12px; }
		.card-body {
			a {
				width: 100%;
				button {
					width: inherit;
					margin-top: 30px;
				}
			}
		}
	}
}
