// Animations -  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Using_CSS_animations
//-----------------------------------------------------------------------------------------------------//
.split-text {
  overflow: hidden;
}

.wave-heading {
  overflow: hidden;
}

// pin card step 1 circle animation
.check-spinner__worm-a,
.check-spinner__worm-b {
	visibility: hidden;
}

.check-spinner {
	overflow: visible;
	width: 30px;
	height: 30px;
  @include media-breakpoint-down(lg) {
    width: 25px;
    height: 25px;
  }
}

// uncomment below code for two rotations animation
// .worm-a_animation {
// 	animation: worm-a 0.5s linear;
// }

.worm-b_animation {
	animation: worm-b 1s 1.5s linear forwards;
	stroke-dashoffset: 0.01;
}

.circle_animation {
	animation: circle 1.5s 1.6s cubic-bezier(0,0,.42,1.33) forwards;
}

/* Animations */
@keyframes worm-a {
	from {
		stroke-dashoffset: 36.1;
		visibility: visible;
	}
	to {
		stroke-dashoffset: -252.9;
		visibility: visible;
	}
}

@keyframes worm-b {
	from {
		animation-timing-function: linear;
		stroke-dasharray: 0 0 72.2 341.3;
		visibility: visible;
	}
	69.7% {
		animation-timing-function: ease-out;
		stroke-dasharray: 0 207 45 341.3;
		visibility: visible;
	}
	to {
		animation-timing-function: ease-out;
		stroke-dasharray: 0 297 45 341.3;
		visibility: visible;
	}
}

@keyframes circle {
	from { r: 0; }
	to { r: 53px; }
}

#highlight-border, #highlight-border-md {
  stroke-dasharray: 561.4;
  stroke-dashoffset: 561.4; /* Replace with your actual path length */
}

.highlight-border-animation {
  animation: dash 1.3s 0.8s ease-in-out forwards;
}

.highlight-border-animation-md {
  animation: dash 2.0s 0.8s ease-in-out forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0; /* Replace with your actual path length */
  }
  from {
    stroke-dashoffset: 561.4;
  }
}

@keyframes reappear {
	from { visibility: hidden; }
	to { visibility: visible; }
}

.checkmark-border-container {
  position: absolute;
  left: 0;
  top: 0;
  .checkmark-svg, .checkmark-svg-lg {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    .checkmark-svg {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    .checkmark-svg-lg {
      display: block;
    }
  }
}

.loading-dots {
  margin-left: -10px;
  animation: loading 0.5s ease-in-out infinite alternate;
  opacity: 0;
  display: inline !important;
}

.loading-dots:nth-child(3) {
  animation-delay: 0.25s;
}

.loading-dots:nth-child(4) {
  animation-delay: 0.5s;
}

.loading-text {
  display: inline !important;
}

@keyframes loading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-blob-animation {
  transform: translateY(-100px);
  width: 480px;
  height: 245px;
}

//loading modal blob animation
.blob g{
  transform: translateX(300px) translateY(300px);
}

.blob-1 path{
  fill: rgba(0, 252, 112, .2);
  animation-name: rotate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.blob-2 path{
  fill: rgba(0, 252, 112, .2);
  animation-name: rotate;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.blob-3 path{
  fill: rgba(0, 252, 112, .2);
  animation-name: rotate;
  animation-duration: 50s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.blob-2{
  transform: translateX(300px) translateY(300px) rotatez(180deg) !important;
}
.blob-3{
  transform: translateX(300px) translateY(300px) rotatez(220deg) !important;
}
.blob-3{
  transform: translateX(300px) translateY(300px) rotatez(300deg) !important;
}
.blob-2 path{
  fill: rgba(0, 252, 112, .2) !important;
}

@keyframes rotate {
  0% {
    transform: rotatez(0deg);
  }
  100% {
    transform: rotatez(360deg);
  }
}

.loading-animation-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @include media-breakpoint-down(md) {
    height: 100%;
  }
}


.fadeup-after-banner{
  opacity: 0
}

.fadein-after-banner{
  opacity: 0
}

.step-3-mask{
  height: 535px;
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    height: 340px;
  }
  @include media-breakpoint-down(md) {
    height: 245px;
    margin-left: 80px;
    margin-bottom: -20px;
  }
}

.footer-animation{
  width: 95vw;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%; /*Add this*/
  transform: translate(-50%, 0);
  background-color: #F0F0F0;
  @include media-breakpoint-down(lg) {
    background-color: transparent;
  }
}

.blob-right-container {
  position: absolute;
  right: 0;
  bottom: 0;
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &.right {
    right: 0;
    bottom: 0;
  }

  .glow {
    position: absolute;
    width: 150px;
    top: -145px;
    right: 90px;
    mix-blend-mode: overlay;
    height: 150px;
    border-radius: 50%;
    background: #6DD400;
    background: linear-gradient(135deg, rgba(109,212,0,1) 0%, rgba(2,0,36,0) 54%);
    @include media-breakpoint-down(lg) {
      width: 100px;
      height: 100px;
      top: -87px;
      right: 53px;
    }
    @include media-breakpoint-down(sm) {
      width: 90px;
      height: 90px;
      top: -85px;
      right: 62px;
    }
  }
}
