.case-study {
	.case-study-container {
		background: linear-gradient(135deg, #f8fff6, #f4faf9);
		border-radius: 35px;
		border: none;
		padding: 108px 96px 70px;
		@include media-breakpoint-down(xl) {
			padding: 108px 56px 70px;
		}
		@include media-breakpoint-down(lg) {
			padding: 90px 36px 45px;
		}
		@include media-breakpoint-down(md) {
			padding: 70px 25px 25px;
		}
		img.chart {
			margin-top: 45px;
			@include media-breakpoint-down(lg) {
				margin: 35px 0;
			}
			@include media-breakpoint-down(md) {
				margin: 30px 0;
			}
		}
		.details {
			margin-top: 40px;
			display: flex;
			@include media-breakpoint-down(lg) {
				margin-top: 0;
				flex-direction: column;
			}
			.overview,
			.results {
				flex-basis: 50%;
				h6 {
					margin-bottom: 25px;
					position: relative;
					display: flex;
					@include media-breakpoint-down(lg) {
						overflow: hidden;
						font-size: 20px;
					}
					@include media-breakpoint-down(md) {
						margin-bottom: 17px;
					}
					&::after {
						content: '';
						position: static;
						margin: 17px 0 0 25px;
						width: 100%;
						height: 1px;
						background-color: #4b525e4a;
					}
				}
			}
			.overview {
				padding-right: 33px;
				@include media-breakpoint-down(lg) {
					margin-bottom: 27px;
					padding-right: 0px;
				}
				h6::after {
					right: 20px;
					@include media-breakpoint-down(xl) {
						width: 228px;
					}
					@include media-breakpoint-down(lg) {
						width: 100%;
						right: unset;
						left: 114px;
					}
				}
				p {
					margin-bottom: 16px;
					@include media-breakpoint-down(sm) {
						margin-bottom: 10px;
					}
				}
				ul {
					li {
						&::marker {
							color: #038f3b;
						}
					}
				}
			}
			.results {
				padding-left: 33px;
				@include media-breakpoint-down(lg) {
					padding-left: 0px;
				}
				h6::after {
					right: 0px;
					@include media-breakpoint-down(xl) {
						width: 298px;
					}
					@include media-breakpoint-down(lg) {
						width: 100%;
						right: unset;
						left: 89px;
					}
				}
				.statistics-container {
					display: flex;
					justify-content: space-between;
					@include media-breakpoint-down(lg) {
						justify-content: flex-start;
					}
					@include media-breakpoint-down(md) {
						flex-direction: column;
					}
					.statistic {
						width: 195px;
						p {
							font-weight: $font-medium;
							margin: 5px 0 26px 0;
							@include media-breakpoint-down(lg) {
								margin: 3px 0 20px 0;
							}
						}
						span {
							font-size: 65px;
							font-weight: $font-bold;
							line-height: 50px;
							@include media-breakpoint-down(lg) {
								font-size: 55px;
								line-height: 50px;
							}
							sub {
								font-size: 34px;
								font-weight: inherit;
								line-height: inherit;
								bottom: 0;
								@include media-breakpoint-down(lg) {
									font-size: 28px;
								}
							}
						}
					}
				}
				.affiliates-container {
					p {
						margin-bottom: 16px;
					}
					.affiliates {
						display: flex;
						flex-wrap: wrap;
						gap: 15px;
						.company {
							background-color: white;
							display: grid;
							place-content: center;
							padding: 14px;
							border-radius: 9px;
							border: 1px solid #e6e6e6;
							@include media-breakpoint-down(lg) {
								padding: 14px 16px;
							}
							@include media-breakpoint-down(md) {
								padding: 10px 14px;
							}
							img {
								height: 25px;
								width: auto;
								margin: 0;
							}
						}
					}
				}
			}
		}
		&.case-study-container-sm {
			position: relative;
			width: calc(100% + 180px);
			left: -90px;
			padding: 86px 90px 75px 90px;
			@include media-breakpoint-down(lg) {
				width: calc(100% + 72px);
				left: -36px;
				padding: 90px 72px 45px 72px;
			}
			@include media-breakpoint-down(md) {
				width: calc(100% + 24px);
				left: -12px;
				padding: 70px 40px 40px;
			}
			.case-study-header {
				position: relative;
				p {
					font-size: 20px;
					line-height: 34px;
					@include media-breakpoint-down(lg) {
						font-size: 18px;
						line-height: 28px;
						margin-top: 18px;
						margin-bottom: 30px;
					}
				}
				sup {
					top: -16px;
					font-size: 18px;
					line-height: 22px;
					font-weight: $font-bold;
					color: #038f3b;
					@include media-breakpoint-down(md) {
						top: -35px;
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
			.details {
				.overview {
					.link-with-circle {
						margin-top: 28px;
					}
					@include media-breakpoint-down(lg) {
						margin-bottom: 43px;
					}
				}
				.results {
					.statistics-container {
						justify-content: flex-start;
						column-gap: 54px;
						.statistic {
							@include media-breakpoint-up(lg) {
								width: 230px;
							}
						}
					}
				}
				.overview,
				.results {
					width: 50%;
					@include media-breakpoint-down(lg) { width: 100%; }
					h6 { margin-bottom: 20px; }
				}
				.link-with-circle {
					@include media-breakpoint-down(lg) { margin-top: 24px; }
					span {
						&::before { z-index: 1; }
						&::after { z-index: 0; }
					}
					img { 
						margin-top: 12px;
					}
				}
			}
		}
	}
}
