main.product-details {
	section.hero {
		margin-top: 80px;
		@include media-breakpoint-down(lg) {
			margin-top: 55px;
		}
		.image-container {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			width: calc(100% - 36px);
			border-radius: 35px;
			padding: 105px 65px 0;
			@include media-breakpoint-down(lg) {
				margin-top: 48px;
				padding: 70px 55px 0;
				width: 100%;
			}

			&.lavender-blush {
				background-color: #fff0f2;
			}
			&.light-cyan {
				background-color: #e0faff;
			}
			&.light-yellow-green {
				background-color: #f0ffb8;
			}
			&.lavender {
				background-color: #f5e8ff;
			}
			&.light-green {
				background-color: #dbffde;
			}
			&.alice-blue {
				background-color: #e4f0ff;
			}

			img {
				width: 100%;
				height: auto;
				border-radius: 10px 10px 0 0;
				border: 10px solid $font-color-primary;
				border-bottom: none;
			}
		}
		.cloud {
			position: relative;
			.card-pill {
				opacity: 0.5;
				transition-duration: 0.2s;
				&:hover {
					opacity: 1;
				}
				&#card-pill-1 {
					left: -90px;
					top: -172px;
				}
				&#card-pill-2 {
					right: -140px;
					top: 270px;
				}
				&#card-pill-3 {
					left: -24px;
					top: 335px;
				}
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
			.hero-text {
				width: 100%;
				h1 {
					text-align: left;
					width: auto;
					margin: 140px 0 24px 0;
					font-size: 62px;
					line-height: 76px;
					span {
						font-weight: $font-medium;
					}
					@include media-breakpoint-down(lg) {
						text-align: center;
						margin: 0 0 14px 0;
						font-size: 46px;
						line-height: 60px;
					}
				}
				.lead {
					font-size: 20px;
					line-height: 34px;
					padding: 0;
					text-align: left;
					font-weight: $font-regular;
					@include media-breakpoint-down(lg) {
						text-align: center;
						font-size: 18px;
						line-height: 28px;
					}
				}
				.link-with-circle {
					margin-top: 22px;
					@include media-breakpoint-down(lg) {
						margin: 25px auto 0;
					}
				}
			}
		}
	}
	section.examples {
		margin-top: 100px;
		@include media-breakpoint-down(lg) {
			margin-top: 90px;
		}

		.section-heading {
			.line {
				width: 100%;
				left: 275px;
				@include media-breakpoint-down(lg) {
					left: 165px;
				}
			}
		}

		.examples-slider {
			margin-top: 60px;
			@include media-breakpoint-down(lg) {
				margin-top: 35px;
			}
			img {
				border-radius: 25px;
				width: 100%;
				height: 300px;
				object-fit: cover;
				object-position: top center;
			}
		}

		.slider-pagination {
			bottom: -69px;
			@include media-breakpoint-down(lg) {
				bottom: -66px;
			}
		}
	}

	section.how-it-works {
		margin-top: 140px;
		@include media-breakpoint-down(lg) {
			margin-top: 163px;
			.col-lg-4 {
				.card-transparent-bg {
					margin-bottom: 50px;
				}
				&:last-child {
					.card-transparent-bg {
						margin-bottom: 0px;
					}
				}
			}
		}

		.section-heading {
			.line {
				width: 100%;
				left: 310px;
				@include media-breakpoint-down(lg) {
					left: 190px;
				}
			}
		}
	}

	section.benefits {
		margin-top: 130px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 60px;
		}
		.benefit-card-container {
			&:nth-child(3n-2) {
				padding-right: 19px;
			}
			&:nth-child(3n-1) {
				padding: 0px 17px;
			}
			&:nth-child(3n) {
				padding-left: 19px;
				padding-right: 12px;
			}
		}
		@include media-breakpoint-down(lg) {
			margin-top: 90px;
		}
		.section-heading {
			.line {
				width: 100%;
				left: 205px;
				@include media-breakpoint-down(lg) {
					left: 120px;
				}
			}
		}
		.section-lead {
			@include media-breakpoint-down(sm) {
				margin-bottom: 25px;
			}
		}
		.design-element {
			position: absolute;
			height: auto;
			&.design-element-desktop {
				width: 360px;
				right: 0px;
				top: -60px;
				@include media-breakpoint-only(md) {
					top: -90px;
				}
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
			&.design-element-mobile {
				width: 294px;
				right: -90px;
				top: -96px;
				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}
		.benefits-container {
			.row {
				row-gap: 36px;
				@include media-breakpoint-down(sm) {
					row-gap: 25px;
				}
			}
		}
	}

	section.case-study {
		margin-top: 130px;
		margin-bottom: 100px;

		@include media-breakpoint-down(lg) {
			margin-top: 60px;
			margin-bottom: 60px;
		}

		p {
			@include media-breakpoint-only(md) {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}

	section.products {
		.section-heading {
			.line {
				width: 100%;
				left: 305px;
				@include media-breakpoint-down(lg) {
					left: 200px;
				}
			}
		}
	}

	section.faqs {
		margin-top: 120px;
		@include media-breakpoint-down(lg) {
			margin-top: 90px;
		}
	}
}
