.brands {
	section.hero {
		margin-top: 54px;
		h1 {
			font-family: $font-secondary;
			font-size: 62px;
			line-height: 76px;
			font-weight: $font-medium;
			font-style: italic;
			text-align: center;
			margin-bottom: 0px;
			@include media-breakpoint-down(lg) {
				font-size: 46px;
				line-height: 60px;
			}
		}
	
		p.section-lead {
			text-align: center;
			margin-bottom: 31px;
      		margin-top: 18px;
			@include media-breakpoint-down(lg) {
				margin-top: 20px;
				margin-bottom: 21px;
			}
		}

		.col-md-8 {
			margin: auto;
		}
	
		.link-with-circle {
			margin: 0 auto 60px auto;
			@include media-breakpoint-down(lg) {
				margin: 0 auto 48px auto;
			}
		}
	
		.hero-video-container {
			iframe {
				border-radius: 40px;
				position: relative;
				width: 100%;
				aspect-ratio: 16 / 9;
				@include media-breakpoint-down(md) {
					border-radius: 25px;
					left: 0px;
					width: 100%;
				}
			}
		}
	}
	
	section.cta {
		margin-top: 90px;
		margin-bottom: 70px;
		background: linear-gradient(229deg, #EDF4FF,#FFF0F2);
		@include media-breakpoint-down(lg) {
			margin-top: 60px;
		}
		.cta-container-2 {
			display: flex;
			width: fit-content;
			margin: auto;
			@include media-breakpoint-down(lg) {
				display: block;
			}
			align-items: center;
			p {
				font-size: 24px;
				line-height: 38px;
				@include media-breakpoint-down(lg) {
					font-size: 22px;
					text-align: center;
					line-height: 32px;
					padding: 0px 10px;
				}
			}
			.link-with-circle {
				margin-top: 0px;
				margin-left: 30px;
				img { z-index: 2; }
				span { z-index: 1; }
				@include media-breakpoint-down(lg) {
					margin: auto;
					margin-top: 25px;
				}
			}
		}
	}

	section.company-carousel {
		margin-bottom: 110px;
		@include media-breakpoint-down(md) {
			margin-bottom: 90px;
		}
	}
	
	section.customer-success {
		.section-heading {
			.line {
				width: 920px;
			}
		}
	
		.success-stories {
			margin-top: 60px;
			@include media-breakpoint-down(md) {
				margin-top: 35px;
			}
			.col-lg-6 {
				&:nth-child(1) {
					@include media-breakpoint-up(lg) {
						padding-right: 20px;
					}
				}
		
				&:nth-child(2) {
					@include media-breakpoint-up(lg) {
						padding-left: 20px;
					}
				}
			}
		}

		//@TODO maybe refactor to card scss?
		.section-cta {
			@include media-breakpoint-down(lg) {
				margin-bottom: 60px;
			}
			.title {
				@include media-breakpoint-down(lg) {
					padding: 35px 0px 0px 0px
				}
				@include media-breakpoint-down(sm) {
					span {
						display: block;
						font-style: italic;
					}
				}
			}
			.content {
				padding: 40px 0px 90px 0px;
				@include media-breakpoint-down(lg) {
					padding: 14px 0px 90px 0px;
				}
			}
		}
	}

	section.how-it-works {
		margin-bottom: 120px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 40px;
		}
	}

	section.benefits {
		margin-bottom: 130px;
		.card-full {
			border-radius: 35px;
			margin-bottom: 90px;
			@include media-breakpoint-down(lg) {
				padding-bottom: 48px;
				&.card-3 {
					margin-bottom: 70px;
				}
			}
			&:not(:last-child) {  
				margin-bottom: 30px;  
				@include media-breakpoint-down(lg) {
					margin-bottom: 25px;
				}
			}
			&.card-1 { background-color: #F0FFB8;  }
			&.card-2 { background-color: #F5E8FF;  }
			&.card-3 { background-color: #DBFFDE;  }
			.content { 
				.image-icon { 
					margin-bottom: 11px; 
					@include media-breakpoint-down(lg) {
						margin-bottom: 15px; 
					}
				} 
			}
			.image-container {
				height: 100%;
				display: flex;
				align-items: center;
        		overflow: hidden;
				img {
					margin-left: auto;
					height: 450px;
					width: auto;
					border: 10px solid $font-color-primary;
					border-right: 0;
					border-radius: 25px 0 0 25px;
					@include media-breakpoint-down(sm) {
						width: 85%;
						height: auto;
						border-width: 5px;
						border-radius: 10px 0 0 10px;
					}
				}
			}
		}

		.section-heading-secondary {
			margin-bottom: 30px;
		}

		.nav {
			&::-webkit-scrollbar {
				display: none;
			}
			@include media-breakpoint-down(lg) {
				flex-wrap: nowrap;
				width: 100%;
				overflow: scroll;
				align-items: center;
			}
		}

		.prev-next-tab-container{
			display: none;
			justify-content: center;
			align-items: center;
			margin: auto;
			margin-top: 30px;
			@include media-breakpoint-down(sm) {
				display: flex;
			}
			button {
				border: 1px solid black;
				border-radius: 50%;
				width: 44px;
				height: 44px;
				background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			span {
        		width: 25px;
				font-size: 18px;
				font-weight: $font-medium;
				margin-left: 24px;
				margin-right: 24px;
        		color: #121A2B;
			}
		}
	}

	section.benefits-cards {
		margin-bottom: 0px;
	}

	section.newsletter {
		margin-bottom: 100px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 90px
		}
		.newsletter-container {
			flex-direction: row;
			align-items: flex-end;
			padding: 64px 0 90px 0px;
			@include media-breakpoint-down(lg) {
				display: block;
				padding: 50px 96px 67px 96px;
			}
			@include media-breakpoint-down(sm) {
				padding: 50px 20px 80px 20px;
			} 
			.heading-container {
				width: 62%;
				padding-right: 30px;
				@include media-breakpoint-down(lg) {
					width: 100%;
					padding-right: 0px;
				}
				.section-heading {
					text-align: left;
					padding: 0;
					position: relative;
					z-index: 1;
					span { display: inline; }
					@include media-breakpoint-only(md) {
						font-size: 54px;;
					}
					@include media-breakpoint-down(lg) {
						text-align: center;
					}
				}
				.section-lead {
					padding: 0;
					text-align: left;
					margin-bottom: 0;
					@include media-breakpoint-down(lg) {
						text-align: center;
						padding: 0px 35px;
						margin-bottom: 25px;
					}
					@include media-breakpoint-down(sm) {
						padding: 0px 20px;
					}
				}
			}
			button {
				width: 235px;
				padding: 15px 0;
				margin-left: 12px;
				@include media-breakpoint-down(lg) {
					margin: auto;
				}
			}
		}
	}

	.section-heading {
		.line {
			left: 300px;
			@include media-breakpoint-down(lg) {
				left: 205px;
			}
			@include media-breakpoint-down(sm) {
				left: 195px;
			}
		}
	}
}

