// Form Styles
//-----------------------------------------------------------------------------------------------------//
.custom-input {
	// ----- MDC TEXT FIELD COMPONENT -----
	&.mdc-text-field.mdc-text-field--outlined {
		font-family: $font-primary;
		margin-bottom: 0px;
		input.mdc-text-field__input {
			font-family: $font-primary;
			font-size: 18px;
			font-weight: $font-medium;
			padding: 13px 0px;
		}
		.mdc-notched-outline__leading {
			border-top-left-radius: 12px;
			border-bottom-left-radius: 12px;
			border-width: 1px;
		}
		.mdc-notched-outline__notch {
			border-width: 1px;

			.mdc-floating-label {
				font-family: $font-primary;
				font-size: 18px;
			}
		}
		.mdc-notched-outline__trailing {
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;
			border-width: 1px;
		}

		&.mdc-text-field--label-floating {
			.mdc-floating-label {
				font-weight: $font-medium;
				font-size: 18px;
			}
		}

		// dark styling for light background
		&.dark {
			input.mdc-text-field__input {
				border: 1px solid $font-color-primary;
				caret-color: $font-color-primary;
			}
			.mdc-notched-outline__leading { border-color: $font-color-primary; }
			.mdc-notched-outline__notch {
				border-color: $font-color-primary;
				.mdc-floating-label {
					color: $font-color-primary;
				}
			}
			.mdc-notched-outline__trailing { border-color: $font-color-primary; }

			// Dark text field when focused/clicked/active
			&.mdc-text-field--focused {
				.mdc-notched-outline__leading { border-color: #037732; }
				.mdc-notched-outline__notch { border-color: #037732; }
				.mdc-notched-outline__trailing { border-color: #037732; }
			}
		}
		// light styling for dark background
		&.light {
			input.mdc-text-field__input {
				color: white;
				border: 1px solid white;
				caret-color: white;
			}
			.mdc-notched-outline__leading { border-color: white; }
			.mdc-notched-outline__notch {
				border-color: white;
				.mdc-floating-label {
					color: white;
					opacity: 0.8;
				}
			}
			.mdc-notched-outline__trailing { border-color: white; }

			&:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
				.mdc-notched-outline__leading { border-color: white; }
				.mdc-notched-outline__notch { border-color: white; }
				.mdc-notched-outline__trailing { border-color: white; }
			}

			// Light text field when focused/clicked/active
			&.mdc-text-field--focused {
				.mdc-notched-outline__leading { border-color: #00FC70; }
				.mdc-notched-outline__notch { border-color: #00FC70; }
				.mdc-notched-outline__trailing { border-color: #00FC70; }
			}
		}
	}

	// ----- MDC SELECT COMPONENT -----
	&.mdc-select.mdc-select--outlined {
		font-family: $font-primary;
		margin-bottom: 0px;
		.mdc-notched-outline__leading {
			border-top-left-radius: 12px;
			border-bottom-left-radius: 12px;
			border-width: 1px;
		}
		.mdc-notched-outline__notch {
			border-width: 1px;
			.mdc-floating-label {
				font-family: $font-primary;
				font-size: 18px;
			}
		}
		.mdc-notched-outline__trailing {
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;
			border-width: 1px;
		}

		&.mdc-select--activated,
		&.mdc-select--focused {
			.mdc-select__dropdown-icon {
				transform: rotate(180deg);
			}
			.mdc-notched-outline {
				.mdc-notched-outline__leading {
					border-width: 1px;
				}
				.mdc-notched-outline__notch {
					border-width: 1px;
				}
				.mdc-notched-outline__trailing {
					border-width: 1px;
				}
			}
		}
		// Dark styling for light background
		&.dark {
			.mdc-select__selected-text {
				color: $font-color-primary;
			}
			.mdc-select__dropdown-icon {
				svg g{
					fill: $font-color-primary;
				}
			}
			.mdc-notched-outline__leading {
				border-color: $font-color-primary;
			}
			.mdc-notched-outline__notch {
				border-color: $font-color-primary;
				.mdc-floating-label {
					color: $font-color-primary;
				}
			}
			.mdc-notched-outline__trailing {
				border-color: $font-color-primary;
			}

			// When select menu is open/focused
			&.mdc-select--activated,
			&.mdc-select--focused {
				.mdc-select__dropdown-icon {
					svg g{
						fill: #037732;
					}
				}
				.mdc-notched-outline {
					.mdc-notched-outline__leading {
						border-color: #037732;;
					}
					.mdc-notched-outline__notch {
						border-color: #037732;;
					}
					.mdc-notched-outline__trailing {
						border-color: #037732;;
					}
				}
			}
		}
		// Light styling for light background
		&.light {
			.mdc-select__selected-text {
				color: white;
			}
			.mdc-select__dropdown-icon {
				svg g{
					fill: white;
				}
			}
			.mdc-select__anchor {
				&:hover {
					.mdc-notched-outline__leading {
						border-color: #ffffff;
					}
					.mdc-notched-outline__notch {
						border-color: #ffffff;
						.mdc-floating-label {
							color: #ffffffcc;
						}
					}
					.mdc-notched-outline__trailing {
						border-color: #ffffff;
					}
				}
			}
			.mdc-notched-outline__leading {
				border-color: #ffffff;
			}
			.mdc-notched-outline__notch {
				border-color: #ffffff;
				.mdc-floating-label {
					color: #ffffff;
				}
			}
			.mdc-notched-outline__trailing {
				border-color: #ffffff;
			}

			// When select menu is open/focused
			&.mdc-select--activated,
			&.mdc-select--focused {
				.mdc-select__dropdown-icon {
					svg g{
						fill: #00FC70;
					}
				}
				.mdc-notched-outline {
					.mdc-notched-outline__leading {
						border-color: #00FC70;
					}
					.mdc-notched-outline__notch {
						border-color: #00FC70;
					}
					.mdc-notched-outline__trailing {
						border-color: #00FC70;
					}
				}
			}
		}
	}
	// MDC Textfield
	&.mdc-text-field--textarea {
		&.dark {
			textarea {
				caret-color: $font-color-primary;
			}
		}
		&.light {
			textarea {
				color: white;
				caret-color: white;
			}
		}
	}

	// Custom radio & checkbox inputs - shared styles
	&.custom-radio-input-container, &.custom-checkbox-input-container {
		display: flex;
		.option-container {
			border: 1px solid #cfd0d4;
			height: 56px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			cursor: pointer;
			&:hover {
				background-color: #121A2B1a; 
			}
			&:has(> input:checked) {
				background-color: #121A2B1a; 
				border: 1px solid $font-color-primary;
			}
			input {
				width: 20px; 
				height: 20px;
				margin-right: 20px;
				accent-color: $font-color-primary;
				flex-shrink: 0;
				@include media-breakpoint-down(lg) {
					margin-right: 12px;
				}
			}
			span {
				font-size: 18px;
				font-weight: $font-medium;
				flex-grow: 1;
				text-align: center;
			}
		}
	}
	
	// Custom radio buttons - specific to radio
	&.custom-radio-input-container {
		&.two-options {
			.option-container {
				width: 150px;
				span {
					margin-left: -20px;
				}
				&:nth-child(1) {
					border-radius: 10px 0 0 10px;
				}
				&:nth-child(2) {
					border-radius: 0 10px 10px 0;
				}
			}
		}
		&.three-options {
			.option-container {
				width: fit-content;
				&:first-child {
					border-radius: 10px 0 0 10px;
					@include media-breakpoint-down(md) {
						border-radius: 10px 10px 0 0;
					}
				}
				&:nth-child(2) {
					border-radius: 0;
				}
				&:last-child {
					border-radius: 0 10px 10px 0;
					@include media-breakpoint-down(md) {
						border-radius: 0 0 10px 10px;
					}
				}
				@include media-breakpoint-down(lg) {
					width: 33.33%;
				}
				@include media-breakpoint-down(md) {
					width: 100%;
				}
				span {
					@include media-breakpoint-down(lg) {
						text-align: left;
					}
				}
			}
		}
	}
	// custom checkbox styles - specific
	&.custom-checkbox-input-container {
		display: flex;
		.option-container {
			border: 1px solid #cfd0d4;
			height: 56px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			cursor: pointer;
			&:has(> input:checked) {
				border: 1px solid $font-color-primary;
				background-color: #121A2B1a; 
			}
			input {
				width: 20px; 
				height: 20px;
				margin-right: 20px;
				accent-color: $font-color-primary;
			}
			span {
				font-size: 18px;
				font-weight: $font-medium;
				flex-grow: 1;
				text-align: center;
			}
		}
		&.four-options {
			.option-container {
				width: 280px;
				@include media-breakpoint-down(xxl) {
					width: 260px
				}
				@include media-breakpoint-down(lg) {
					width: 336px
				}
				@include media-breakpoint-down(md) {
					width: 100%
				}
				span {
					text-align: left;
				}
				&:nth-child(1) {
					border-radius: 10px 0 0 0;
					@include media-breakpoint-down(lg) {
						border-radius: 10px 10px 0 0;
					}
				}
				&:nth-child(2) {
					border-radius: 0 10px 0 0;
					@include media-breakpoint-down(lg) {
						border-radius: 0;
					}
				}
				&:nth-child(3) {
					border-radius: 0 0 0 10px;
					@include media-breakpoint-down(lg) {
						border-radius: 0;
					}
				}
				&:nth-child(4) {
					border-radius: 0 0 10px 0;
					@include media-breakpoint-down(lg) {
						border-radius: 0 0 10px 10px;
					}
				}
			}
		}
	}

	// custom number input
	&.counter-container {
		display: flex;
		align-items: center;
		@include media-breakpoint-down(sm) { width: 100%; }
		input {
			height: 56px;
			text-align: center; 
			border: none;
			border-top: 1px solid #121A2B;
			border-bottom: 1px solid #121A2B;
			border-radius: 0px;
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			@include media-breakpoint-down(sm) {
				width: auto;
				flex: 1;
			}
		}

		.count-btn {
			display: grid;
			place-content: center;
			font-size: 30px;
			width: 56px;
			height: 56px;
			padding: 0 !important;
			background-color: #121a2b1a;
			border-width: 1px;
			margin-top: 0 !important;
			&:hover {
				background-color: $font-color-primary;
				color: white;
			}
			&:first-of-type { 
				border-right: none;
				border-radius: 10px 0 0 10px;
				padding: 0 0 4px 0 !important;
			}
			&:last-of-type {
				border-left: none;
				border-radius: 0 10px 10px 0;
			}
			@include media-breakpoint-down(lg) {
				flex-shrink: 0;
			}
		}
	}
}

.form-row {
	.form-input {
		label.form-label {
			color: #121a2bCC;
			font-size: 20px;
			font-weight: $font-medium;
			margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				font-size: 18px;
				line-height: 25px;
				margin-bottom: 18px;
			}
		}
	}
}

input {
	font-size: 18px;
	font-weight: $font-medium;
	padding: 13px 19px;
	border: 1px solid $font-color-primary;
	border-radius: 12px;
	background-color: transparent;
	&::placeholder {
		color: #121a2bcc;
	}
}

// .radio-container,.btn-checkbox-container {
// 	border: 1px solid #cfd0d4;
// 	height: 56px;
// 	display: flex;
// 	align-items: center;
// 	padding: 0 18px;
// 	input {
// 		margin-right: 12px;
// 		@include media-breakpoint-down(md) {
// 			margin-right: auto;
// 		}
// 	}
// 	label {
// 		font-size: 18px;
// 		line-height: 22px;
// 		font-weight: $font-medium;
// 		@include media-breakpoint-down(md) {
// 			margin-right: auto;
// 		}
// 	}

// 	.custom-radio-input,.custom-checkbox-input {
// 		accent-color: $font-color-primary;
// 		width: 20px;
// 		height: 20px;
// 	}
// }

a:focus,
button:focus,
input:focus,
textarea:focus {
	outline: none;
}

.alert {
	//
}

.alert-success {
	//
}
.alert-warning {
	//
}
.alert-danger {
	//
}

.alert-info {
	//
}
