.section-cta {
	.title {
		padding: 55px 0 0 0;
		@include media-breakpoint-down(xl) {
			padding: 60px 0 0 0px;
		}
		@include media-breakpoint-down(lg) {
			padding: 40px 55px 0px;
		}
		@include media-breakpoint-down(md) {
			padding: 22px 25px 0px;
		}
		h5 {
			span {
				font-family: $font-secondary;
				font-weight: $font-medium;
			}
		}
	}
	.content {
		padding: 57px 95px 80px 0px;
		@include media-breakpoint-down(xl) {
			padding: 60px 80px 70px 0px;
		}
		@include media-breakpoint-down(lg) {
			padding: 20px 55px 50px;
		}
		@include media-breakpoint-down(md) {
			padding: 14px 25px 43px;
		}
		p {
			font-size: 24px;
			line-height: 38px;
			margin-bottom: 22px;
			@include media-breakpoint-down(lg) {
				font-size: 22px;
				line-height: 32px;
				margin-bottom: 20px;
			}
		}
		.link-with-circle {
			span { z-index: 1; }
			img { z-index: 2; }
		}
	}
}
