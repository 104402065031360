nav.mobile-nav {
    display: none;

    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1100;

    .mobile-nav-blob-shape {
        position: absolute;
        top: 0px;
        right: -110px;
        width: 225px;
        height: auto;
        z-index: -1;
    }

    .nav-controls {
        display: flex;
        justify-content: space-between;
        z-index: 1200;
        margin-top: 25px;
        @include media-breakpoint-only(md) {
            margin-top: 35px;
        }
        .mobile-nav-close {
            cursor: pointer;
        }
    }

    .links-container {
        padding: 40px 28px 35px;
        list-style: none;
        @include media-breakpoint-only(md) {
            padding: 40px 28px 40px;
        }
        li.nav-item {
            margin-bottom: 0px;
            &:not(.sub-item):not(:first-of-type) {
                margin-top: 27px;
            }
            .nav-link {
                width: fit-content;
                &::before {
                    top: 25px;
                }
                &.mobile-dropdown-trigger {
                    cursor: pointer;
                    &.dropdown-open {
                        img {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .dropdown-items {
            display: none;
            ul {
                list-style: none;
                border-top: 1px solid #EFEFEF;
                border-bottom: 1px solid #EFEFEF;
                width: calc(100% + 80px);
                left: -40px;
                background: #FBFBFB;
                padding: 18px 0px 18px 50px;
                margin-top: 20px;
                li {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin-bottom: 18px;
                    &:last-child { margin-bottom: 0px;  }
                    .icon-container {
                        width: 24px;
                        height: 24px;
                        display: grid;
                        place-content: center;
                        border-radius: 6px;
                    }
                    .img-arrow {
                        margin-top: 3px;
                    }
                    &.links {
                        .icon-container { background-color: #DBFFDE; }
                    }
                    &.roi-blueprint {
                        .icon-container { background-color: #FFF0F2; }
                    }
                    &.brand-mentions {
                        .icon-container { background-color: #F5E8FF; }
                    }
                    &.brand-features {
                        .icon-container { background-color: #E9EFF8; }
                    }
                    &.interviews {
                        .icon-container { background-color: #F0FFB8; }
                    }
                    &.expert-quotes {
                        .icon-container { background-color: #E0FAFF; }
                    }
                }
            }
        }
    }

    .account-controls {
        display: flex;
        flex-direction: column;
        padding: 0 25px;
        position: relative;
        margin-top: 35px;
        @include media-breakpoint-only(md) {
            flex-direction: row;
            justify-content: space-between;
            padding: 0 60px;
        }
        &::before {
            content: "";
            position: absolute;
            top: -35px;
            width: 100%;
            left: 0px;
            height: 1px;
            background-color: #97979754;
        }
        .btn {
            font-size: 16px;
            width: 100%;
            height: 50px;
            padding: 12px 0 13px;
            @include media-breakpoint-only(md) {
                width: 280px;
            }
            &:not(:last-of-type) {
                margin-bottom: 25px;
            }
        }
    }
}