// Header & Navigation Styles
//-------------------------------//
header {
	margin-top: 15px;
	font-family: $font-primary;
	background-color: transparent;
	.navbar {  
		padding: 0; 
		opacity: 0;
	}
	.container {
		position: relative;
	}
	.lottie-container{
		overflow: hidden;
		width: 300px;
		@include media-breakpoint-down(md) {
			width: 200px;
		}
	}
	.navbar-brand {
		margin: 0;
		padding: 0;
		.nav-logo-container {
			height: 100px;
			width: 550px;
			transform: translateX(-120px);
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(md) {
				height: 51px;
				width: 421px;
			}
		}
		.nav-logo { 
			overflow: hidden;
			@include media-breakpoint-down(md) {
				height: 186px !important;
			}
		}
	}
	.lottie-offset{ transform: translateX(-30px); }
	.navbar-nav-top {
		@include media-breakpoint-up(lg) {
			margin-left: -120px;
		}
	}
	.navbar-nav {
		position: static;
		column-gap: 30px;
		@include media-breakpoint-down(md) {
			display: none;
		}
		.nav-item {
			margin: 0px;
		}
		.dropdown-main {
			position: static;
		}
	}
	.btn {
		&.btn-primary {
			width: 215px;
		}
		&.btn-outline {
			width: 105px;
		}
		@include media-breakpoint-down(lg) {
			margin-left: auto;
			margin-right: 24px;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.blob-shape {
		position: absolute;
		top: -40px;
		right: -400px;
		width: auto;
		height: 279px;
		z-index: -1;
		@include media-breakpoint-down(md) {
			height: 107px;
			top: -15px;
			right: -72px;
		}
	}

	.mobile-nav-open {
		cursor: pointer;
		width: 27px;
		height: auto;
	}

	&.fixed-header {
		margin-top: 25px;
		position: fixed;
		width: 100%;
		z-index: 1001;
		top: -135px;
		transition: 0.25s top ease;
		@include media-breakpoint-down(md) {
			margin-top: 15px;
			top: -190px;
		}
		.container {
			border: 1px solid #EFEFEF;
			border-radius: 50vh;
			padding: 18px 30px 18px 40px;
			box-shadow: 0px 2px 4px #4B525E1F;
			background-color: white;
			width: 1296px;
			@include media-breakpoint-down(xxl) { width: 1116px; }
			@include media-breakpoint-down(xl) { width: 936px; }
			@include media-breakpoint-only(lg) { padding: 18px 18px 18px 40px; }
			@include media-breakpoint-down(lg) { width: 696px; }
			@include media-breakpoint-down(md) { width: 516px; }
			@include media-breakpoint-down (sm) {
				width: 100%;
				padding: 16px 20px;
				margin: 0 15px;
			}
			.navbar-brand {
				img {
					height: 46px;
					width: auto;
					@include media-breakpoint-only(lg) {
						height: 40px;
					}
					@include media-breakpoint-down(md) {
						height: 40px;
					}
					@include media-breakpoint-down(md) {
						height: 32px;
					}
				}
			}
			.navbar-nav {
				column-gap: 15px;
				@include media-breakpoint-only(lg) {
					column-gap: 8px;
				}
				.dropdown-fixed {
					position: static;
				}
			}
			.account-controls {
				column-gap: 15px;
				.btn {
					&.btn-outline { width: 95px; }
					&.btn-primary { width: 200px; }
				}
			}
		}

		&.nav-open {
			.container {
				border-radius: 50px 50px 0 0;
				box-shadow: none;
			}
		}
	}
	&.nav-open {
		.dropdown {
			background: #121a2b14;
			border-radius: 50vw;
			a svg {
				transform: rotate(180deg);
				margin-bottom: 2px;
			}
		}
	}
}