section.how-it-works {
    .section-heading {
        .line {
            width: 100%;
        }
    }
    p.section-lead {
        @include media-breakpoint-down(sm) {
            margin-bottom: 23px;
        }
    }
    .steps-container {
        padding-top: 12px;
        .card-header {
            h3 {
                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                    line-height: 28px;
                }
                span {
                    margin-bottom: 12px;
                }
            }
            @include media-breakpoint-down(sm) {
                padding-top: 20px;
            }
        }
    }

    .card-full {
        .content { margin: 90px 0 90px 90px; }
    }

    .card-body {
        p {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .step-3 {
        @include media-breakpoint-only(md) {
          margin:auto;
          margin-top: 36px;
        }
    }
}
