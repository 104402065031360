section.company-carousel {
    margin-bottom: 64px;
    @include media-breakpoint-down(lg) {
        margin-bottom: 40px;
    }
    p.lead {
        display: block;
        font-size: 26px;
        font-weight: $font-medium;
        line-height: 50px;
        text-align: center;
        @include media-breakpoint-down(md) {
            font-size: 22px;
            line-height: 36px;
        }
    }

    .logo-container-lg, .logo-container {
        overflow: hidden;
        margin-top: 35px;
        align-items: center;
        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }

        .logos {
            display: flex;
            img {
                height: 46px;
                width: auto;
                margin-right: 60px;
                position: relative;
                @include media-breakpoint-down(sm) {
                    margin-right:28px
                }
            }
        }
    }

    .logo-container {
        .top-container {
            display: flex;
            margin-bottom: 20px;
        }
        .bottom-container {
            display: flex;
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .logo-container-lg {
        display: flex;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}