// Footer Styles
//-----------------------------------------------------------------------------------------------------//t

footer {
    .footer-container {
        position: relative;
        width: calc(100% + 180px);
        left: -90px;
        background-color: #F0F0F0;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        padding: 68px 90px 0px;
        @include media-breakpoint-down(lg) {
            width: calc(100% + 72px);
            left: -36px;
            padding: 50px 36px 0;
        }
        @include media-breakpoint-down(md) {
            width: calc(100% + 60px);
            left: -30px;
            padding: 44px 0 0 0;
        }
        .footer-section {
            border-bottom: 1px solid #4B525E;
        }
        .footer-head {
            padding-bottom: 40px;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
            .footer-logo {
                @include media-breakpoint-down(lg) {
                    height: 40px;
                    width: auto;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 26px;
                }
            }
            .social-medias {
                display: flex;
                column-gap: 20px;
                @include media-breakpoint-down(md) {
                    column-gap: 18px;
                }
                .item {
                    width: 45px;
                    height: 45px;
                    border: 1px solid $font-color-primary;
                    display: grid;
                    place-content: center;
                    img {
                        @include media-breakpoint-down(md) {
                            height: 15px;
                            width: 15px;
                        }
                    }
                    &:hover {
                        background-color: $font-color-primary;
                        img { filter: invert(1); }
                    }
                    @include media-breakpoint-down(md) {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        #menu-footer-column-1,
        #menu-footer-column-2,
        #menu-footer-column-3,
        #menu-footer-column-4 {
            padding-left: 0;
            li {
                list-style: none;
                a {
                    font-size: 18px;
                    font-weight: $font-medium;
                    line-height: 25px;
                    width: fit-content;
                    border-bottom: 2px solid transparent;
                    &:not(:last-child) {  margin-bottom: 17px;  }
                    @include media-breakpoint-down(lg) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    &:hover {
                        color: #121A2B;
                        border-bottom: 2px solid #15A857 !important;
                    }
                }
            }
        }

        .footer-links {
            padding: 33px 0 47px 0;
            column-gap: 30px;
            @include media-breakpoint-down(lg) {
                padding: 35px 0 50px;
            }
            @include media-breakpoint-down(md) {
                display: grid !important;
                grid-template-columns: calc(50% - 12px) calc(50% - 12px);
                column-gap: 24px;
                row-gap: 32px;
                padding: 26px 30px 47px;
            }
            @include media-breakpoint-down(sm) {
                padding: 26px 17px 47px;
            }
            .item {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                h6 {
                    font-size: 22px;
                    font-weight: $font-bold;
                    line-height: 30px;
                    margin-bottom: 24px;
                    @include media-breakpoint-down(lg) {
                        font-size: 19px;
                        line-height: 30px;
                        margin-bottom: 18px;
                    }
                }
                a {
                    font-size: 18px;
                    font-weight: $font-medium;
                    line-height: 25px;
                    width: fit-content;
                    border-bottom: 2px solid transparent;
                    &:not(:last-child) {  margin-bottom: 18px;  }
                    @include media-breakpoint-down(lg) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
        .footer-bottom {
            padding: 20px 0;
            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: center;
                row-gap: 7px;
                padding: 18px 0;
            }
            p {
                font-size: 17px;
                font-weight: $font-medium;
                line-height: 24px;
                @include media-breakpoint-down(lg) {
                    font-size: 15px;
                    line-height: 22px
                }
            }
        }
        .logo-cutoff {
            margin-top: 70px;
            @include media-breakpoint-down(xl) {
                margin-top: 55px;
                position: relative;
                top: 15px;
            }
            @include media-breakpoint-down(lg) {
                margin-top: -12px;
                top: 30px;
            }
            @include media-breakpoint-down(md) {
                margin-top: -53px;
                top: 44px;
            }
        }
    }
}