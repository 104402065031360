@font-face {
	font-family: $font-primary;
	src: url('../../public/fonts/Satoshi-Variable.ttf');
}

@font-face {
	font-family: $font-secondary;
	src: url('../../public/fonts/Fraunces-Variable.ttf');
}

@font-face {
	font-family: $font-secondary;
	src: url('../../public/fonts/Fraunces-VariableItalic.ttf');
    font-style: italic;
}