section.products {
    margin-bottom: 120px;
    @include media-breakpoint-down(lg) {
        margin-bottom: 90px;
    }
    .products-container { 
        .row { 
            row-gap: 60px;
            @include media-breakpoint-down(lg) {
                row-gap: 45px;
            }
            .col-lg-4 {
                @include media-breakpoint-up(lg) {
                    &:nth-child(3n-2) {
                        padding-right: 18px;
                    }
                    &:nth-child(3n-1) {
                        padding-right: 18px;
                        padding-left: 18px;
                    }
                    &:nth-child(3n) {
                        padding-left: 18px;
                    }
                }
            }
        } 
    }
}