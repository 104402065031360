
$white: #ffffff;
$black: #22292f; 

$font-color-primary: #121A2B;

$se-purple: #D487FF;
$se-blue: #66E0FF;
$se-green: #D6FC4A;
// Color palette from darkest to lightest - uncomment what you need for the project and refine the colors
//-----------------------------------------------------------------------------------------------------------//
// $red-darkest: #3b0d0c;
// $red-darker: #621b18;
// $red-dark: #cc1f1a;
$red: #e3342f;
// $red-light: #ef5753;
// $red-lighter: #f9acaa;
// $red-lightest: #fcebea;

// $orange-darkest: #462a16;
// $orange-darker: #613b1f;
// $orange-dark: #de751f;
$orange: #f6993f;
// $orange-light: #faad63;
// $orange-lighter: #fcd9b6;
// $orange-lightest: #fff5eb;

// $yellow-darkest: #453411;
// $yellow-darker: #684f1d;
// $yellow-dark: #f2d024;
$yellow: #ffed4a;
// $yellow-light: #fff382;
// $yellow-lighter: #fff9c2;
// $yellow-lightest: #fcfbeb;

// $green-darkest: #0f2f21;
// $green-darker: #1a4731;
// $green-dark: #1f9d55;
$green: #38c172;
// $green-light: #51d88a;
// $green-lighter: #a2f5bf;
// $green-lightest: #e3fcec;

// $teal-darkest: #0d3331;
// $teal-darker: #20504f;
// $teal-dark: #38a89d;
$teal: #4dc0b5;
// $teal-light: #64d5ca;
// $teal-lighter: #a0f0ed;
// $teal-lightest: #e8fffe;

// $blue-darkest: #12283a;
// $blue-darker: #1c3d5a;
// $blue-dark: #2779bd;
$blue: #3490dc;
// $blue-light: #6cb2eb;
$blue-lighter: #bcdefa;
// $blue-lightest: #eff8ff;

// $indigo-darkest: #191e38;
// $indigo-darker: #2f365f;
// $indigo-dark: #5661b3;
$indigo: #6574cd;
// $indigo-light: #7886d7;
// $indigo-lighter: #b2b7ff;
// $indigo-lightest: #e6e8ff;

// $purple-darkest: #21183c;
// $purple-darker: #382b5f;
// $purple-dark: #794acf;
$purple: #9561e2;
// $purple-light: #a779e9;
// $purple-lighter: #d6bbfc;
// $purple-lightest: #f3ebff;

// $pink-darkest: #451225;
// $pink-darker: #6f213f;
// $pink-dark: #eb5286;
$pink: #f66d9b;
// $pink-light: #fa7ea8;
// $pink-lighter: #ffbbca;
// $pink-lightest: #ffebef;

$gray-darkest: #3d4852;
$gray-darker: #606f7b;
$gray-dark: #8795a1;
$gray: #b8c2cc;
$gray-light: #dae1e7;
$gray-lighter: #f1f5f8;
$gray-lightest: #f8fafc;

// Bootstrap Shade Ma
//----------------------//
$grays: (
  "100": $gray-darkest,
  "200": $gray-darker,
  "300": $gray-dark,
  "400": $gray,
  "500": $gray-light,
  "600": $gray-lighter,
  "700": $gray-lightest,
);

// https://getbootstrap.com/docs/4.2/getting-started/theming/
$colors: (
	"blue": $blue,
	"indigo": $indigo,
	"purple": $purple,
	"pink": $pink,
	"red": $red,
	"orange": $orange,
	"yellow": $yellow,
	"green": $green,
	"teal": $teal,
	"white": $white,
	"gray": $gray-600,
);

$theme-colors: (
	"primary": $blue,
	"secondary": $gray-darkest,
	"success": $green,
	"warning": $warning,
	"info": $blue-lighter,
	"light": $gray-lightest,
	"dark": $black,
);

// example of using colors from bootstrap color map
// .custom-element {
//   color: gray("100") or color("white");
//   background-color: theme-color("dark");
// }

// Font Stacks
//-------------------------------------------//
$font-primary: "Satoshi"; // will falback bootstrap native font stack @see https://getbootstrap.com/docs/4.0/content/reboot/?optionsRadios=option1#native-font-stack
$font-secondary: "Fraunces";

// Font Sizes 
//----------------------------//
$font-xs: .75rem;     // 12px
$font-sm: .875rem;    // 14px
$font-base: 1rem;     // 16px
$font-lg: 1.125rem;   // 18px
$font-xl: 1.25rem;    // 20px
$font-2xl: 1.5rem;    // 24px
$font-3xl: 1.875rem;  // 30px
$font-4xl: 2.25rem;   // 36px
$font-5xl: 3rem;      // 48px

// Font Weights
//-----------------------//
$font-hairline: 100;
$font-thin: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// Line Heights
//----------------------//
$leading-none: 1;
$leading-tight: 1.25;
$leading-normal: 1.5;
$leading-loose: 2;