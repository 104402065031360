.link-with-circle {
	margin-top: 30px;
	width: fit-content;
	transition: 0.25s all;
	span {
		position: relative;
		font-size: 20px;
		font-weight: $font-medium;
		line-height: 1.4;
		margin-top: 4px;
		transition: 0.25s all;
		white-space: nowrap;
		@include media-breakpoint-down(lg) {
			font-size: 18px;
			line-height: 24px;
		}
		&::before {
			content: "";
			position: absolute;
			width: 6px;
			height: 6px;
			right: -37px;
			top: 16px;
			background-color: $font-color-primary;
			border-radius: 50vh;
			opacity: 0;
			@include media-breakpoint-down(lg) {
				top: 15px;
			}
		}
		&::after {
			content: "";
			position: absolute;
			width: 46px;
			height: 46px;
			background-color: #00FC70;
			border-radius: 50vh;
			margin-left: 26px;
			z-index: -1;
			top: -6px;
			transition: 0.25s all;
			@include media-breakpoint-down(lg) {
				width: 44px;
				height: 44px;
				top: -8px;
			}
		}
	}
	img {
		margin-top: 8px;
		margin-left: 10px;
		position: relative;
		transition: 0.25s all;
	}

	&:active,&:hover,&:focus {
		color: $font-color-primary;
		span {
			&::before { 
				opacity: 1;
				transform: translateY(-50%);
				transition: 0.25s opacity;
				transition-delay: 0.25s;
			}
			&::after {
				content: "";
				position: absolute;
				width: 46px;
				height: 46px;
				background-color: #00FC70;
				// border: 1px solid #00FC70;
				border-radius: 50vh;
				margin-left: 10px;
				z-index: -1;
				top: -6px;
				@include media-breakpoint-down(lg) {
					top: -8px;
				}
			}
		}
		img {
			margin-left: 32px;
		}
	}
}

.text-container {
	position: relative;
	display: flex;
	flex-direction: column;
	z-index: 3;
	sup {
		font-weight: $font-bold;
		color: #038F3B;
	}
	p { font-weight: $font-regular; }
	.expert-info {
		display: flex;
		img {
			position: absolute;
			transform: scale(-1);
			z-index: -1;
		}
		span {
			font-weight: $font-medium;
			position: relative;
			&:first-of-type {
				&::before {
					content: "";
					position: absolute;
					background-color: #038F3B;
					border-radius: 50vh;
				}
			}
			&:last-of-type {
				&::before {
					content: "";
					position: absolute;
					border-radius: 50vh;
					background-color: #CACACA;
				}
			}
		}
	}
}
