section.benefits {
    margin-bottom: 100px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 90px;
    }
    .card-full {
        border-radius: 35px;
        margin-bottom: 90px;
        @include media-breakpoint-down(lg) {
            padding-bottom: 48px;
            &.card-3 {
                margin-bottom: 70px;
            }
        }
        &:not(:last-child) {  margin-bottom: 30px;  }
        &.card-1 { background-color: #F0FFB8;  }
        &.card-2 { background-color: #F5E8FF;  }
        &.card-3 { background-color: #DBFFDE;  }
        .content { .image-icon { margin-bottom: 11px; } }
        .image-container {
            height: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;
            img {
                margin-left: auto;
                height: 430px;
                width: auto;
                border: 10px solid $font-color-primary;
                border-right: 0;
                border-radius: 25px 0 0 25px;
                @include media-breakpoint-down(sm) {
                    width: 85%;
                    height: auto;
                    border-width: 5px;
                    border-radius: 10px 0 0 10px;
                }
            }
        }
    }

    .section-heading-secondary {
        margin-bottom: 30px;
    }

    .nav {
        &::-webkit-scrollbar {
            display: none;
        }
        @include media-breakpoint-down(lg) {
            flex-wrap: nowrap;
            width: 100%;
            overflow: scroll;
            align-items: center;
        }
    }

    .prev-next-tab-container{
        display: none;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 30px;
        @include media-breakpoint-down(sm) {
            display: flex;
        }
        button {
            border: 1px solid black;
            border-radius: 50%;
            width: 44px;
            height: 44px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            width: 25px;
            font-size: 18px;
            font-weight: $font-medium;
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
