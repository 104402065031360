// Base Styles - What your project's base elements should look like by default
//-----------------------------------------------------------------------------------------------------//

* {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	font-family: $font-primary;
	color: $font-color-primary;
	font-size: $font-base;
	line-height: $leading-normal;
	overflow-x: hidden;
}

.app {
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
input {
	color: $font-color-primary;
	margin-bottom: 0px;
}

h1 {
	font-size: 68px;
	font-weight: $font-regular;
	line-height: 88px;
	margin-bottom: 26px;
	@include media-breakpoint-down(lg) {
		font-size: 52px;
		line-height: 70px;
	}
	span {
		font-family: $font-secondary;
		padding: 1px 12px;
		&.purple {
			background-color: $se-purple;
		}
		&.blue {
			background-color: $se-blue;
		}
		&.green {
			background-color: $se-green;
		}
	}

	&.small {
		font-size: 62px;
		line-height: 76px;
		font-weight: $font-regular;
		text-align: center;
		span {
			&.italic {
				font-family: $font-secondary;
				font-weight: $font-medium;
				font-style: italic;
			}
		}
		@include media-breakpoint-down(lg) {
			font-size: 46px;
			line-height: 60px;
		}
	}
}

h2 {
	font-size: 54px;
	font-weight: $font-regular;
	line-height: 70px;
	@include media-breakpoint-down(lg) {
		font-size: 34px;
		line-height: 46px;
	}

	&.section-heading {
		.primary-font {
			font-style: normal;
			font-family: $font-primary;
			font-weight: $font-regular;
		}
		span {
			font-family: $font-secondary;
			font-weight: $font-medium;
			font-style: italic;
		}
		.line-text-container {
			display: flex;
			&::after {
				border-top: 1px solid #4b525e4a;
				content: "";
				width: 1000px;
				margin-top: 38px;
				margin-left: 50px;
				flex: 1;
				@include media-breakpoint-down(lg) {
					margin-left: 40px;
					margin-top: 23px;
				}

			}
		}
		.block {
			font-family: $font-primary;
			font-weight: $font-regular;
			font-style: normal;
			display: block;
			position: relative;
			width: fit-content;
		}
	}

	&.small {
		font-size: 40px;
		font-weight: $font-regular;
		line-height: 44px;
		margin-bottom: 15px;
		@include media-breakpoint-down(lg) {
			font-size: 32px;
			margin-bottom: 10px;
		}
		@include media-breakpoint-down(md) {
			font-size: 30px;
			margin-bottom: 8px;
			text-align: center;
		}
		span {
			font-family: $font-secondary;
			font-style: italic;
			font-weight: $font-medium;
		}
	}
	&.large {
		@include media-breakpoint-only(lg) {
			font-size: 54px;
			line-height: 70px;
		}
		span {
			font-family: $font-secondary;
			font-style: italic;
		}
	}
}

h3 {
	font-size: 35px;
	font-weight: $font-medium;
	line-height: 50px;
	position: relative;
	@include media-breakpoint-down(lg) {
		font-size: 25px;
		line-height: 35px;
	}
	&.process-heading {
		sup {
			position: absolute;
			top: -30px;
			font-size: 18px;
			font-weight: $font-bold;
			@include media-breakpoint-down(lg) {
				font-size: 16px;
				top: -15px;
			}
		}
	}

	&.case-study-heading {
		text-align: center;
		padding: 0 105px;
		@include media-breakpoint-down(lg) {
			padding: 0 20px;
			font-size: 30px;
			line-height: 44px;
		}
		@include media-breakpoint-down(md) {
			padding: 0;
			font-size: 25px;
			line-height: 35px;
		}
		sup {
			font-size: 18px;
			font-weight: $font-bold;
			position: absolute;
			top: -36px;
			left: 50%;
			transform: translateX(-50%);
			color: #038f3b;
			@include media-breakpoint-down(md) {
				font-size: 16px;
			}
		}
	}
	&.section-heading-secondary {
		font-size: 46px;
		line-height: 60px;
		font-weight: $font-regular;
		text-align: center;
		@include media-breakpoint-down(md) {
			font-size: 32px;
			line-height: 45px;
		}
		span {
			display: block;
			&.italic {
				display: inline;
				font-family: $font-secondary;
				font-weight: $font-medium;
				font-style: italic;
			}
		}
	}
	&.before-and-after-heading {
		span {
			font-family: $font-secondary;
			font-weight: $font-medium;
			padding: 5px 12px;
			margin-right: 10px;
			&.dull {
				background-color: #ecf8fe;
			}
			&.bright {
				background-color: #00fc70;
			}
		}
	}
}

h4 {
	font-size: 26px;
	font-weight: $font-bold;
	line-height: 34px;
	@include media-breakpoint-down(lg) {
		font-size: 22px;
		line-height: 28px;
	}
}

h5 {
	&.section-cta-heading {
		font-size: 30px;
		font-weight: $font-regular;
		line-height: 44px;
		@include media-breakpoint-down(lg) {
			font-size: 28px;
			line-height: 42px;
		}
	}
}

h6 {
	font-size: 22px;
	font-weight: $font-bold;
	line-height: 28px;
}

p {
	font-size: 18px;
	font-weight: $font-regular;
	line-height: 28px;
	margin-bottom: 0px;
	@include media-breakpoint-down(lg) {
		font-size: 16px;
		line-height: 24px;
	}
	&.lead {
		font-size: 22px;
		line-height: 36px;
		@include media-breakpoint-down(lg) {
			font-size: 20px;
			line-height: 32px;
		}
	}
	&.section-lead {
		font-size: 20px;
		line-height: 34px;
		margin: 25px 0 60px 0;
		@include media-breakpoint-down(lg) {
			font-size: 18px;
			line-height: 28px;
			margin: 20px 0 45px 0;
		}
	}
}

a {
	transition: all 0.2s;
	font-size: 16px;
	position: relative;
	text-decoration: none;
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		&.nav-link {
			// &::before {
			// 	content: '';
			// 	position: absolute;
			// 	top: 40px;
			// 	left: 50%;
			// 	transform: translateX(-50%);
			// 	width: 100%;
			// 	height: 2px;
			// 	background-color: #15a857;
			// 	z-index: 10;
			// }
		}
		&.footer-link {
			color: $font-color-primary;
			border-bottom: 2px solid #15a857 !important;
		}
	}
	&.nav-link {
		padding: 0px;
		font-size: 18px;
		font-weight: $font-medium;
		color: $font-color-primary;
		&.main-link {
			padding: 8px 15px;
			&:hover,
			&:active,
			&:focus {
				// color: #15a857;
				&::before {
					display: none;
				}
			}
		}
		&.dropdown-toggle {
			&::after {
				display: none;
			}
			&.show {
				svg {
					transform: rotate(180deg);
				}
			}
		}
		&:active,
		&:hover,
		&:focus {
			color: $font-color-primary;
		}
	}
	&.card-link {
		&:hover {
			.btn-round {
				background-color: $font-color-primary !important;
				svg path {
					stroke: transparent;
					fill: white;
				}
			}
		}
	}
}

blockquote {
	position: relative;
	padding: 50px 39px 20px;
	font-size: 22px;
	line-height: 30px;
	border-left: 0;

	p,
	q {
		font-size: $font-lg;
		line-height: 36px;
		font-weight: $font-medium;
	}

	cite {
		margin-top: 10px;
		display: block;
		font-style: normal;
		font-weight: $font-bold;
		color: $black;
	}
}

ul {
	position: relative;
	padding-left: 13px;
	li {
		font-size: 18px;
		margin-bottom: 10px;
		font-weight: $font-regular;
		&:last-child {
			margin-bottom: 0px;
		}
		@include media-breakpoint-down(lg) {
			font-size: 16px;
		}
	}
}

ol {
	position: relative;
	padding-left: 0;
	margin-bottom: 30px;
	list-style: none;
	counter-reset: ol-counter;
	li {
		position: relative;
		padding-left: 15px;
		font-weight: $font-light;
		font-family: $font-primary;
		margin-bottom: 10px;
	}
	& > li:before {
		position: absolute;
		left: 0px;
		top: 0px;
		font-weight: $font-medium;
		content: counter(ol-counter);
		counter-increment: ol-counter;
		color: inherit;
	}
}

hr {
	margin-top: 30px;
	margin-bottom: 30px;
	border: 0;
	height: 2px;
}

table {
	width: 100%;
	th {
		padding: 11px 25px;
		min-width: 210px;
		text-align: inherit;
		font-weight: $font-medium;
		font-size: 16px;
	}
	td {
		padding: 11px 25px;
		font-family: $font-primary;
		font-weight: $font-light;
		font-size: 15px;
		line-height: 25px;
	}
}

mark {
	background: theme-color('secondary');
}

em {
	color: theme-color('primary');
}

.cdp-copy-loader-overlay {
	position: fixed;
	bottom: 0px;
}