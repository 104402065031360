.slick-slider {
    .slick-prev,.slick-next {
        position: absolute;
        bottom: -75px;
        background-color: transparent;
        border: 1px solid $font-color-primary;
        width: 46px;
        height: 46px;
        display: grid;
        place-content: center;
        z-index: 1000;
        @include media-breakpoint-down(lg) {
            bottom: -70px;
            width: 44px;
            height: 44px;
        }
        &.slick-disabled {
            background-color: #F3F3F3 !important;
            border-color: transparent !important;
            svg path {
                stroke: #C1C1C1 !important;
                fill: #C1C1C1 !important;
            }
        }
    }
    .slick-prev { 
        right: 60px;
        @include media-breakpoint-down(md) {
            right: unset;
            left: calc(50% - 88px);
        }
    }
    .slick-next { 
        right: 0px;
        @include media-breakpoint-down(md) {
            right: unset;
            left: calc(50% + 43px);
        }
    }
    .slick-list {
        margin: 0 -12px;
        .slick-track {
            display: flex;
            padding-bottom: 5px;
            .slick-slide {
                margin: 0 12px;
            }
        }
    }
}
.slider-pagination {
    position: absolute;
    right: 136px;
    font-size: 20px;
    font-weight: $font-medium;
    line-height: 34px;
	bottom: -69px;
    @include media-breakpoint-down(md) {
        font-size: 18px;
        right: unset;
        left: calc(50% - 17px);
		bottom: -66px;
    }
}