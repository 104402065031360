header {
    .navbar-nav {
        .dropdown-menu {
            position: absolute;
            background: transparent;
            top: 60px;
            left: 12px;
            width: calc(100% - 24px);
            float: unset;
            padding: 0;
            border: none;
            border-radius: 0px;
            z-index: 999;
            .dropdown-menu-container {
                background: transparent;
                border: 1px solid #EFEFEF;
                border-radius: 50px;
                box-shadow: 0 2px 4px #4B525E1F;
                margin-top: 35px;
            }
            .dropdown-menu-contents {
                background: white;
                display: grid;
                grid-template-columns: 250px 250px 250px auto;
                column-gap: 35px;
                padding: 30px 30px 40px 40px;
                border-radius: 50px 50px 0 0;
                @include media-breakpoint-down(xxl) {
                    grid-template-columns: 210px 210px 210px auto;
                }
                .dropdown-menu-col {
                    .dropdown-menu-col-heading {
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: $font-bold;
                        margin-bottom: 35px;
                        min-height: 28px;
                        @include media-breakpoint-down(xxl) {
                            min-height: 56px;
                        }
                    }
                    .link-container {
                        margin-bottom: 40px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                        .header {
                            display: flex;
                            column-gap: 6px;
                            .heading {
                                font-size: 18px;
                                line-height: 26px;
                                font-weight: $font-medium;
                            }
                            .icon-container {
                                width: 24px;
                                height: 24px;
                                display: grid;
                                place-content: center;
                                border-radius: 6px;
                            }
                            .img-arrow {
                                margin-top: 3px;
                            }
                        }
                        .body {
                            margin-top: 8px;
                            p {
                                font-size: 16px;
                                line-height: 25px;
                                font-weight: $font-regular;
                            }
                        }
                        &.links {
                            .icon-container { background-color: #DBFFDE; }
                        }
                        &.roi-blueprint {
                            .icon-container { background-color: #FFF0F2; }
                        }
                        &.brand-mentions {
                            .icon-container { background-color: #F5E8FF; }
                        }
                        &.brand-features {
                            .icon-container { background-color: #E9EFF8; }
                        }
                        &.interviews {
                            .icon-container { background-color: #F0FFB8; }
                        }
                        &.expert-quotes {
                            .icon-container { background-color: #E0FAFF; }
                        }
                    }
                    &.case-study {
                        .case-study-container {
                            padding: 20px 30px 30px;
                            .header p{
                                font-size: 16px;
                                line-height: 28px;
                                font-weight: $font-bold;
                                color: #038F3B;
                                margin-bottom: 6px;
                            }
                            .body p {
                                font-size: 18px;
                                line-height: 28px;
                                font-weight: $font-bold;
                                margin-bottom: 16px;
                            }
                            .metrics-container {
                                display: flex;
                                column-gap: 10px;
                                .metric {
                                    background: #038F3B;
                                    padding: 15px 15px 20px;
                                    border-radius: 14px;
                                    .value {
                                        font-size: 35px;
                                        line-height: 30px;
                                        font-weight: $font-bold;
                                        color: white;
                                        margin-bottom: 0px;
                                        span {
                                            font-size: 12px;
                                            line-height: 12px;
                                        }
                                    }
                                    .descriptor {
                                        display: flex;
                                        column-gap: 3px;
                                        margin-top: 8px;
                                        p {
                                            color: white;
                                            font-size: 14px;
                                            line-height: 19px;
                                            font-weight: $font-medium;
                                            margin-bottom: 0px;
                                        }
                                    }
                                }
                            }
                            .footer {
                                .link-with-circle {
                                    span {
                                        &::before, &::after {
                                            z-index: 0;
                                        }
                                        &::after {
                                            top: -6px;
                                        }
                                    }
                                    &:hover {
                                        span {
                                            &::before {
                                                top: 16.5px;
                                                z-index: 1;
                                            }
                                            &::after {
                                                top: -6px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .dropdown-menu-cta {
                background: #191D24;
                border-radius: 0 0 50px 50px;
                .cta-link-container {
                    display: flex;
                    column-gap: 15px;
                    align-items: center;
                    justify-content: center;
                    padding: 24px 0;
                    p,a {
                        color: white;
                        font-weight: $font-medium;
                    }
                    p {
                        font-size: 20px;
                        line-height: 26px;
                    }
                    a {
                        display: flex;
                        font-size: 18px;
                        line-height: 22px;
                        text-decoration: underline;
                        img { margin-left: 10px; }
                    }
                }
            }
        }
    }
    
    &.fixed-header {
        .dropdown-menu {
            width: calc(100% + 2px); // Weird border width issue fix, update this eventually
            top: 60px;
            left: -1px;
            .dropdown-menu-container {
                margin-top: 26px;
                border-radius: 0 0 50px 50px;
                background: white;
            }
        }
    }
}