main.pricing {
	.hero {
		margin-top: 50px;
		h1 {
			text-align: center;
			margin-bottom: 0;
			span {
				&.italic {
					display: block;
				}
			}
		}
		.section-lead {
			text-align: center;
			width: 50%;
			margin: 18px auto 26px auto;
			@include media-breakpoint-down(lg) {
				width: 66%;
			}
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
		.link-with-circle {
			flex-direction: column;
			margin: 0 auto;
			height: 60px;
			span {
				&::before {
					position: absolute;
					right: 50%;
					top: 48px;
					transform: translateX(50%);
					opacity: 0;
				}
				&::after {
					margin-left: 0px;
					top: 58px;
					left: 35px;
					@include media-breakpoint-down(lg) {
						left: 30px;
					}
				}
			}
			img {
				margin-top: 30px;
				margin-left: 0px;
				transform: rotate(90deg);
			}
			&:active,&:hover,&:focus {
				color: $font-color-primary;
				span {
					&::before { 
						opacity: 1;
						transition: 0.25s opacity;
						transition-delay: 0.25s;
					}
					&::after {
						content: "";
						position: absolute;
						width: 46px;
						height: 46px;
						background-color: #00FC70;
						border-radius: 50vh;
						z-index: -1;
						top: 30px;
						@include media-breakpoint-down(lg) {
							top: -8px;
						}
					}
				}
				img {
					margin-top: 40px;
				}
			}
		}
	}

	.find-a-plan {
		margin-top: 130px;
		@include media-breakpoint-down(lg) {
			margin-top: 100px;
		}
		.find-a-plan-container {
			background-color: #E0FAFF;
			border-radius: 35px;
			padding: 45px 70px 65px;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			justify-content: space-between;
			align-items: flex-end;
			overflow: hidden;
			@include media-breakpoint-down(lg) {
				padding: 40px 30px 50px;
			}
			h2 {
				font-size: 48px;
				line-height: 62px;
				@include media-breakpoint-down(lg) {
					font-size: 34px;
					line-height: 43px;
				}
			}
			button.btn-find-a-plan {
				width: 240px;
				@include media-breakpoint-down(lg) {
					margin-top: 30px;
				}
				@include media-breakpoint-down(md) {
					margin: 100px auto 0 auto;
					width: 210px;
				}
			}
			.design-element {
				position: absolute;
				width: 360px;
				height: auto;
				top: -12px;
				right: 0;
			}

			form {
				display: none;
				width: 100%;
				margin-top: 20px;
				z-index: 2;
				& > p {
					font-size: 20px;
					line-height: 34px;
					@include media-breakpoint-down(md) {
						font-size: 18px;
						line-height: 28px;
					}
				}
				.form-label {
					color: $font-color-primary;
					font-size: 20px;
					font-weight: $font-medium;
					opacity: 0.8;
					margin-top: 0px;
				}
				.form-row {
					width: 100%;
					display: flex;
					column-gap: 30px;
					margin-top: 45px;
					@include media-breakpoint-only(md) {
						flex-wrap: wrap;
						column-gap: 0;
					}
					@include media-breakpoint-down(md) {
						flex-direction: column;
					}
					&.small {
						column-gap: 24px;
						margin-top: 35px;
						align-items: center;
						@include media-breakpoint-down(md) {
							margin-top: 50px;
						}
					}
					.form-input {
						display: flex;
						flex-direction: column;
						&:first-child { 
							width: 240px;
							@include media-breakpoint-only(md) {
								width: 50%;
								padding-right: 12px;
							}
							@include media-breakpoint-down(md) {
								width: 100%;
							}
							.form-label {
								@include media-breakpoint-down(md) {
									margin-bottom: 18px;
								}
							}
						}
						&:nth-child(2) {
							@include media-breakpoint-only(md) {
								width: 50%;
								padding-left: 12px;
							}
							.counter-container {
								input {
									width: 90px;
								}
							}
						}
						&:last-child {
							@include media-breakpoint-only(md) {
								width: 100%;
								margin-top: 30px;
							}
							.custom-radio-input-container {
								@include media-breakpoint-down(md) {
									flex-direction: column;
								}
							}
						}
						@include media-breakpoint-down(md) {
							&:not(:last-child) {
								margin-bottom: 24px;
							}
						}
					}
				}
				button.btn-plans {
					width: 210px;
					@include media-breakpoint-down(md) {
						margin-bottom: 18px;
					}
				}
				a.more-plans {
					font-size: 20px;
					font-weight: $font-medium;
					line-height: 28px;
					text-decoration: underline;
				}
			}
		}
	}

	.plans {
		margin-top: 120px;
		@include media-breakpoint-down(lg) { margin-top: 110px; }
		.section-heading { text-align: center; }
		.section-lead {
			width: 75%;
			margin: 25px auto 95px auto;
			text-align: center;
			@include media-breakpoint-down(lg) {
				margin: 16px 0 35px 0;
				width: 100%;
			}
		}
		.sticky-row {
			position: sticky;
			top: 50px;
		}
		.plans-table {
			hr {
				color: #DCDCDC;
				margin: 30px 0 24px;
				opacity: 1;
				&:first-of-type {
					margin-top: 12px;
				}
				@include media-breakpoint-down(md) {
					margin: 28px 0 22px;
					&:first-of-type { margin-top: 28px; }
				}
			}
			.table-row {
				display: flex;
				justify-content: space-between;
			}
			.table-entry {
				width: 220px;
				text-align: center;
				font-size: 20px;
				line-height: 28px;
				font-weight: $font-medium;
				&.table-info {
					width: 180px;
					text-align: left;
					* {
						font-size: 16px;
						line-height: 25px;
					}
					p.info-title {
						font-weight: $font-medium;
						margin-bottom: 4px;
					}
					img {
						width: 26px;
						height: 26px;
						margin-bottom: 10px;
					}
					@include media-breakpoint-down(lg) {
						width: 100%;
						display: flex;
						flex-direction: column;
					}
				}
				&.table-package {
					display: flex;
					position: relative;
					flex-direction: column;
					padding: 20px 25px 22px;
					border-radius: 25px;
					@include media-breakpoint-down(lg) {
						width: 100%;
						align-items: center;
						padding: 20px 0px 30px;
						margin-bottom: 30px;
					}
					.best-plan {
						position: absolute;
						width: 100%;
						height: 35px;
						left: 0px;
						top: -35px;
						border-radius: 25px 25px 0 0;
						font-size: 16px;
						font-weight: $font-bold;
						display: grid; // turn on when showing
						display: none;
						place-content: center;
					}
					.package-heading {
						font-size: 30px;
						font-weight: $font-medium;
						margin-bottom: 15px;
						@include media-breakpoint-down(md) {
							font-size: 25px;
							line-height: 35px;
							margin-bottom: 8px;
						}
					}
					.package-cost {
						font-size: 45px;
						line-height: 45px;
						font-weight: $font-bold;
						margin-bottom: 12px;
						span {
							font-size: 18px;
							line-height: 18px;
						}
						@include media-breakpoint-down(md) { font-size: 40px; }
					}
					.package-credits {
						color: #343348;
						font-size: 18px;
						line-height: 28px;
						font-weight: $font-bold;
						margin-bottom: 30px;
						@include media-breakpoint-down(md) {
							font-size: 16px;
							line-height: 24px;
							margin-bottom: 25px;
						}
					}
					.package-button {
						height: 50px;
						width: 100%;
						font-size: 16px;
						@include media-breakpoint-down(lg) { width: 170px; }
					}
					&.package-starter { 
						background-color: #EDF1F3;
						.best-plan { background-color: #DFEEF6; }
					}
					&.package-plus { 
						background-color: #F0FFB8;
						.best-plan { background-color: #D6FC4A; }
					}
					&.package-pro { 
						background-color: #F5E8FF;
						.best-plan { background-color: #D487FF; }
					}
					&.package-agency { 
						background-color: #DBFFDE;
						.best-plan { background-color: #00FC70; }
					}
				}
				&.feature { 
					width: 180px;
					text-align: left;
					@include media-breakpoint-down(md) {
						width: 185px;
					}
					&.with-video {
						span {
							text-decoration: underline;
							cursor: pointer;
						}
						img {
							width: 24px;
							margin-left: 8px;
						}
					}
				}
				&.compare {
					margin-top: 40px;
					font-size: 22px;
					line-height: 50px;
					font-weight: $font-bold;
					width: fit-content;
				}
			}
			.plan-highlight {
				position: absolute;
				width: 220px;
			}

			&.plans-table-desktop {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
			&.plans-table-mobile {
				position: sticky;
				top: 30px;
				height: 80vh;
				overflow: auto;
				nav {
					position: sticky;
					top: 0;
					z-index: 1;
					background-color: #fff;
				}
				.nav-tabs {
					border-top: 1px solid #eee;
					border-bottom: 1px solid #eee;
					padding: 12px 0;
					button {
						color: #121a2bab;
						border-radius: 50vw;
						font-size: 22px;
						line-height: 35px;
						font-weight: $font-medium;
						padding: 0 10px;
						border: none;
						@include media-breakpoint-only(md) {
							width: 156px;
							padding: 0;
						}
						&.active {
							color: $font-color-primary;
							&:nth-child(1) { background-color: #EDF1F3; }
							&:nth-child(2) { background-color: #F0FFB8; }
							&:nth-child(3) { background-color: #F5E8FF; }
							&:nth-child(4) { background-color: #DBFFDE; }
						}
					}
				}

				.tab-content {
					margin-top: 24px;
					.tab-pane {
						background-color: white;
						padding: 0;
						@include media-breakpoint-only(md) {
							width: 64%;
							margin: 0 auto;
						}
						.table-entry {
							img { width: 26px; }
							&.table-info {
								div {
									display: flex;
									column-gap: 10px;
								}
							}
							&.table-package.lower { 
								padding: 5px 0 8px;
								margin-bottom: 0;
							}
						}
					}
				}				
				@include media-breakpoint-up(lg) {	display: none; }
			}
		}
	}

	.company-carousel {
		margin-top: 116px;
		background: linear-gradient(90deg, #E0FAFF, #ECFDEF);
		padding: 55px 0 85px 0;
		@include media-breakpoint-down(lg) {
			margin-top: 66px;
		}
		@include media-breakpoint-down(md) {
			margin-top: 36px;
		}
	}

	.before-and-after {
		margin-top: 100px;
		@include media-breakpoint-down(lg) {
			margin-top: 90px;
		}
		.design-element {
			position: absolute;
			width: 775px;
			height: auto;
			top: 40px;
			left: 50%;
			transform: translate(-50%);
			z-index: -1;
			@include media-breakpoint-only(md) {
				width: 750px;
				top: 50px;
			}
		}
		.before-and-after-visual {
			position: relative;
			.visual-row {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 55px;
				padding: 0 50px;
				@include media-breakpoint-down(lg) {
					padding: 0;
				}
				&:last-child { margin-bottom: 0px; }
				&.row-header {
					margin-bottom: 75px;
					padding: 0 90px 0 72px;
					@include media-breakpoint-down(lg) {
						padding: 0 60px 0 50px;
						margin-bottom: 45px;
					}
					@include media-breakpoint-down(md) {
						padding: 0;
						margin-bottom: 28px;
					}
					img {
						height: 60px;
						width: auto;
						margin-left: -7px;
						@include media-breakpoint-down(lg) {
							height: 50px;
						}
					}
				}
				.icon-container {
					width: 70px;
					height: 70px;
					background-color: #F8F8F8;
					display: grid;
					place-content: center;
					padding: 18px;
					border-radius: 50vw;
					position: relative;
					&::before {
						content: "";
						position: absolute;
						width: 112px;
						background-color: black;
						height: 1px;
						top: 50%;
						right: 70px;
						transform: translateY(-50%);
						@include media-breakpoint-down(lg) {
							width: 33px;
							right: 32px;
						}
					}
					&::after {
						content: "";
						position: absolute;
						width: 112px;
						background-color: black;
						height: 1px;
						top: 50%;
						left: 70px;
						transform: translateY(-50%);
						@include media-breakpoint-down(lg) {
							width: 33px;
							left: 32px;
						}
					}
					@include media-breakpoint-down(lg) {
						background: transparent;
						border: none;
						img {
							display: none;
						}
					}
					.line-container {
						position: absolute;
						height: 0px;
						width: 0px;
						&::after {
							content: "";
							position: absolute;
							height: 200px;
							width: 1px;
							top: 70px;
							left: 35px;
							background-color: black;
							@include media-breakpoint-down(lg) {
								height: 216px;
								top: 35px;
							}
						}
					}
				}
			}
			.step-3 {
				.icon-container {
					.line-container{
						display: none;
					}
				}
			}
			&.before-and-after-visual-desktop {
				max-width: 1113px;
				margin: auto;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}
		.before-and-after-slider-container {
			@include media-breakpoint-up(md) {
				display: none;
			}
			.design-element {
				width: 550px;
				top: -50px;
			}
		}
	}

	.case-study {
		margin-top: 180px;
		@include media-breakpoint-down(lg) {
			margin-top: 160px;
		}
		.case-study-container {
			@include media-breakpoint-up(lg) {
				padding: 108px 96px 70px;
			}
		}
	}

	.cost {
		.cost-container {
			position: relative;
			width: calc(100% + 180px);
			left: -90px;
			background-color: #191D24;
			border-radius: 35px;
			padding: 64px 0 90px;
			position: relative;
			@include media-breakpoint-down(lg) {
				width: 100%;
				left: 0px;
				padding: 50px 60px 80px;
			}
			@include media-breakpoint-down(md) {
				padding: 50px 40px 75px;
			}
			.design-element {
				z-index: 0;
				position: absolute;
				width: 420px;
				height: auto;
				&.left {
					left: 0px;
					top: 0px;
				}
				&.right {
					right: 0px;
					bottom: 0px;
				}
			}
			.header,.body {
				position: relative;
				z-index: 1;
			}
			.header {
				h2 {
					color: white;
					text-align: center;
				}
				.section-lead {
					color: #EBEBEB;
					text-align: center;
				}
			}
			.body {
				display: flex;
				align-items: center;
				justify-content: center;
				column-gap: 32px;
				@include media-breakpoint-down(md) {
					flex-direction: column;
				}
				form {
					display: flex;
					flex-direction: column;
					@include media-breakpoint-down(md) {
						width: 100%;
					}
					.form-input {
						&:first-child {
							margin-bottom: 24px;
						}
						@include media-breakpoint-only(md) {
							display: flex;
							align-items: center;
						}
					}
					label:not(.mdc-floating-label) {
						color: #EBEBEB;
						font-size: 20px;
						line-height: 22px;
						margin-right: 15px;
						@include media-breakpoint-down(lg) {
							font-size: 18px;
							margin-bottom: 0px;
						}
						@include media-breakpoint-down(md) {
							margin-bottom: 15px;
						}
					}
					input { color: white; }
					.custom-input {
						margin-right: 0px;
						@include media-breakpoint-down(md) {
							width: 100%;
						}
						&.cost-form-select-service { 
							@include media-breakpoint-up(lg) {
								min-width: 212px;
							}
						}
						&.cost-form-select-website { 
							@include media-breakpoint-up(lg) {
								min-width: 325px;
							}
							@include media-breakpoint-only(md) {
								width: 100%;
							}
						}
					}
				}
				.output {
					padding: 20px 50px 40px;
					background-color: #31353B80;
					border-radius: 25px;
					@include media-breakpoint-down(lg) {
						padding: 20px 30px 30px;
						text-align: center;
					}
					@include media-breakpoint-down(md) {
						padding: 20px 0 30px;
						width: 100%;
						margin-top: 24px;
					}
					p {
						color: #ebebeb;
						font-size: 20px;
						line-height: 34px;
						@include media-breakpoint-down(lg) {
							font-size: 18px;
							line-height: 28px;
						}
						&.cost {
							font-size: 65px;
							font-weight: $font-bold;
							line-height: 50px;
							color: white;
							@include media-breakpoint-down(lg) {
								font-size: 45px;
							}
							span {
								font-size: 36px;
								vertical-align: super;
								@include media-breakpoint-down(lg) {
									font-size: 26px;
								}
							}
						}
					}
				}
			}
			.footer {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 60px;
				row-gap: 25px;
				@include media-breakpoint-down(md) {
					margin-top: 45px;
				}
				h3 {
					color: white;
					font-size: 30px;
					line-height: 44px;
					span {
						font-family: $font-secondary;
						font-style: italic;
						font-weight: $font-medium;
					}
					@include media-breakpoint-down(lg) {
						font-size: 28px;
						line-height: 42px;
						text-align: center;
					}
				}
				button {
					height: 56px;
					width: 234px;
					z-index: 4;
					@include media-breakpoint-down(md) {
						width: 175px;
						height: 50px;
						font-size: 16px;
					}
				}
			}
		}
	}

	.faqs {
		margin-top: 100px;
	}
}