// Buttons
//------------------//

// Base
.btn {
	font-size: 18px;
	font-family: $font-primary;
	font-weight: $font-medium;
	line-height: 22px;
	transition: 0.25s all;
	display: grid;
	place-content: center;
	height: 50px;
	@include media-breakpoint-down(lg) { height: 50px; }
	&:active,
	&:hover,
	&:focus {
		//
	}
	&:focus {
		box-shadow: none;
	}
}

// Default Button
.btn-default {
	//
	&:active,
	&:hover,
	&:focus {
		//
	}
}

// Primary Button
.btn-primary {
	background-color: #121a2b;
	border: 1px solid #121a2b;
	color: white;
	position: relative;
	background-position: 100% 50%;
	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: 0.25s left;
	}
	&:active,
	&:hover,
	&:focus {
		border: 1px solid $font-color-primary !important;
		background-color: transparent !important;
		color: $font-color-primary !important;
		box-shadow: none !important;
	}
}

.btn-primary-fluid-transition {
	border: 1px solid #121a2b;
	color: white;
	position: relative;
	overflow: hidden;
	&::before {
		width: 200%;
		height: 200%;
		background-color: #121a2b;
		position: absolute;
		top: -50%;
		left: -50%;
		transition: all 0.3s;
		border-radius: 50%;
		content: '';
		z-index: -1;
	}
	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: 0.25s left;
	}
	&:active,
	&:hover,
	&:focus {
		border: 1px solid $font-color-primary !important;
		background-color: transparent !important;
		color: $font-color-primary !important;
		box-shadow: none !important;
		&::before {
			transform: translateY(-80%);
		}
	}
}

// Secondary Button
.btn-secondary {
	background-color: white;
	color: $font-color-primary;
	&:active,
	&:hover,
	&:focus {
		//
	}
}

.btn-tertiary {
	background-color: #00fc70;
	&:active,
	&:hover,
	&:focus {
		border: 1px solid #00fc70;
		background-color: #31FB8B;
	}
}

.btn-outline {
	border: 1px solid #121a2b;
	color: #121a2b;
	overflow: hidden;

	&:active,
	&:hover,
	&:focus {
		background-color: $font-color-primary;
		color: white;
	}
}

.btn-outline-fluid-transition-black {
	border: 1px solid #121a2b;
	color: #121a2b;
	overflow: hidden;
	span {
		display: block;
	}
	&:active,
	&:hover,
	&:focus {
		color: white;
		&::before {
			top: -20%;
		}
	}
	&::before {
		width: 200%;
		height: 200%;
		border-radius: 50%;
		position: absolute;
		top: 100%;
		left: -50%;
		background-color: black;
		transition: all 0.2s;
		content: '';
		z-index: -1;
	}
}

.btn-link {
	color: $font-color-primary;
	text-decoration: none;
	border: none;
	background: transparent;
	&:hover {
		color: $font-color-primary;
		text-decoration: underline;
	}
}

.btn-google {
	padding-left: 20px !important;
	&:active,
	&:hover,
	&:focus {
		background-color: #fbfbfb;
		color: $font-color-primary;
	}
}

.btn-close {
	background: none;
	position: absolute;
	width: 49px;
	height: 49px;
	display: grid;
	place-content: center;
	background-color: $font-color-primary;
	opacity: 1;
	padding: 0;
	&:focus,
	&:active {
		box-shadow: none;
	}
	@include media-breakpoint-down(sm) {
		width: 28px;
		height: 28px;
	}
	&:hover {
		opacity: 1;
	}
}

.btn-round {
	width: 35px;
	height: 35px;
	align-self: flex-end;
	display: grid;
	place-content: center;
	background-color: transparent;
	border: 1px solid $font-color-primary;
	margin-top: auto;
	transition: 0.25s all;
}

.accordion-button {
	svg path {
		transform-origin: 50%;
		transition: 0.35s all;
	}
	&:active,
	&:hover {
		.icon-container {
			background-color: $font-color-primary;
			svg path {
				fill: white;
			}
		}
	}
	&:not(.collapsed) {
		.icon-container {
			svg path {
				&:last-of-type {
					transform: rotate(90deg);
				}
			}
		}
	}
}
