section.faqs {
	margin-bottom: 130px;
	@include media-breakpoint-down(md) {
		margin-bottom: 93px;
	}
	.section-heading {
		margin-bottom: 60px;
		.line {
			width: 100%;
            left: 300px;
			@include media-breakpoint-down(lg) {
				left: 180px;
			}
		}
		}
		.accordion {
			.col-lg-6 {
				&:nth-child(odd) {
					@include media-breakpoint-up(lg) {
						padding-right: 30px;
					}
				}
	
				&:nth-child(even) {
					@include media-breakpoint-up(lg) {
						padding-left: 30px;
					}
				}
			}
		.accordion-item {
			border: 1px solid #F4F4F4;
			border-radius: 20px;
			box-shadow: 0px 2px 4px #4B525E1A;
			padding: 30px 30px 30px 35px;
      		margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				padding: 16px 20px 16px 25px;
			}
			&:not(:last-child) { margin-bottom: 20px; }
			.accordion-header {
				.accordion-button {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					font-size: 22px;
					line-height: 32px;
					font-weight: $font-medium;
					padding: 0;
					@include media-breakpoint-down(md) {
						font-size: 20px;
						line-height: 28px;
					}
					&:not(.collapsed) {
						color: $font-color-primary;
						background-color: transparent;
						box-shadow: none;
					}
					.icon-container {
						border: 1px solid $font-color-primary;
						margin-left: 16px;
						flex-shrink: 0;
						height: 32px;
						width: 32px;
						display: grid;
						place-content: center;
						transition: 0.25s all;
						@include media-breakpoint-down(md) {
							height: 25px;
							width: 25px;
						}
						svg {
							@include media-breakpoint-down(md) {
								width: 12px;
								height: 12px;
							}
						}
					}

					&:focus { box-shadow: none; }
					&::after {
						display: none;
					}
				}
			}
			.accordion-collapse {
				.accordion-body {
					padding: 35px 0 0 0;
					@include media-breakpoint-down(lg) {
						padding: 30px 0 0 0;
					}
					@include media-breakpoint-down(md) {
						padding: 24px 0 0 0;
					}
					p {
						font-size: 20px;
						line-height: 28px;
						@include media-breakpoint-down(md) {
							font-size: 18px;
							line-height: 26px;
						}
					}
				}
			}
		}
	}
    @include media-breakpoint-down(md) {
        .col-lg-6 {
            &:first-child {
                .accordion {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
