main.contact {
	section.hero {
		margin-top: 50px;
		h1 { margin-bottom: 0; }
		.section-lead { text-align: center; }
	}

	section.contact-form {
		.submission-container {
			background-color: #038F3B;
			// display: flex;  @TODO:Turn on when submitting form
			display: none;
			align-items: center;
			column-gap: 10px;
			border-radius: 12px;
			padding: 16px 18px 18px;
			p {
				color: white;
				font-size: 20px;
				line-height: 26px;
				font-weight: $font-medium;
			}
		}
		h2.section-heading {
			margin: 22px 0 50px;
			@include media-breakpoint-down(lg) {
				margin: 0 auto 35px auto;
				text-align: center;
				width: 100%;
			}
			@include media-breakpoint-down(md) {
				width: 67%;
			}
		}
		form {
			padding-right: 40px;
			@include media-breakpoint-down(lg) {
				padding-right: 0px;
			}
			.form-row {
				margin-bottom: 35px;
				@include media-breakpoint-down(lg) {
					margin-bottom: 30px;
				}
				&:last-child { margin: 0; }
				.form-input {
					width: 100%;
					.custom-input {
						width: 100%;
						&.custom-radio-input-container {
							display: flex;
							.btn-radio-container {
								width: 150px;
								@include media-breakpoint-down(md) {
									width: 50%;
								}
								&:nth-child(1) { border-radius: 10px 0 0 10px; }
								&:nth-child(2) { border-radius: 0 10px 10px 0; }
							}
						}
						&.custom-checkbox-input-container {
							display: flex;
							flex-wrap: wrap;
							@include media-breakpoint-only(md) {
								flex-direction: column;
							}
							.btn-checkbox-container {
								width: 280px;
								@include media-breakpoint-down(xxl) { width: 260px; }
								@include media-breakpoint-down(lg) { width: 336px }
								@include media-breakpoint-down(md) { width: 100%; }
								&:nth-child(1) {
									border-radius: 10px 0 0 0;
									@include media-breakpoint-down(lg) {
										border-radius: 10px 10px 0 0;
									}
								}
								&:nth-child(2) {
									border-radius: 0 10px 0 0;
									@include media-breakpoint-down(lg) {
										border-radius: 0 0 0 0;
									}
								}
								&:nth-child(3) {
									border-radius: 0 0 0 10px;
									@include media-breakpoint-down(lg) {
										border-radius: 0 0 0 0;
									}
								}
								&:nth-child(4) {
									border-radius: 0 0 10px 0;
									@include media-breakpoint-down(lg) {
										border-radius: 0 0 10px 10px;
									}
								}
							}
						}
						&.mdc-text-field--textarea {
							textarea {
								resize: none;
								height: 120px;
							}
						}
					}
					.additional-website-widget {
						display: flex;
						flex-direction: column;
						.additional-website-widget-controls {
							display: flex;
							align-items: center;
							padding-left: 18px;
							margin-top: 14px;
							img {
								width: 20px;
								height: 20px;
								margin-right: 6px;
								@include media-breakpoint-down(md) {
									width: 18px;
									height: 18px;
								}
							}
							button {
								padding: 0;
								font-size: 16px;
								font-weight: $font-medium;
							}
						}
						.additional-input-container {
							// display: flex;
							// flex-direction: column;
							.additional-input-element {
								position: relative;
								input {
									width: 100%;
									margin-top: 18px;
								}
								img {
									position: absolute;
									right: 18px;
									top: 50%;
									width: 16px;
									height: auto;
									cursor: pointer;
									z-index: 10;
								}
							}
						}
					}
					.hidden-input-container {
						margin-top: 35px;
						display: none;
						@include media-breakpoint-down(md) {
							margin-top: 30px;
						}
						&.hidden-input-become-publisher {
							.alert-container {
								background-color: #66E0FF24;
								padding: 16px 18px 20px;
								display: flex;
								align-items: flex-start;
								column-gap: 10px;
								border: none;
								border-radius: 10px;
								width: fit-content;
								@include media-breakpoint-down(md) {
									padding: 12px 15px 15px;
								}
								img {
									width: 28px;
									height: 28px;
									@include media-breakpoint-down(md) {
										width: 22px;
										height: 22px;
									}
								}
								.alert-info {
									background-color: transparent;
									display: flex;
									flex-direction: column;
									p,a {
										font-size: 20px;
										line-height: 26px;
										font-weight: $font-medium;
										@include media-breakpoint-down(md) {
											font-size: 18px;
											line-height: 22px;
										}
									}
									a {
										text-decoration: underline;
										width: fit-content;
									}
								}
							}
						}
					}
				}
				.contact-form-submit {
					width: 210px;
					@include media-breakpoint-down(lg) {
						width: 330px;
					}
					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}
			}
		}
		.sidebar-container {
			@include media-breakpoint-only(md) {
				margin-top: 50px;
				display: flex;
				column-gap: 24px;
			}
		}
	}
	section.case-study {
		margin-top: 130px;
		@include media-breakpoint-down(lg) {
			margin-top: 110px;
		}
		@include media-breakpoint-down(md) {
			margin-top: 90px;
		}

		p {
			@include media-breakpoint-only(md) {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}
	section.faqs {
		margin-top: 100px;
		@include media-breakpoint-down(md) {
			margin-top: 90px;
		}
	}
}