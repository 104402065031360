.alert {
    background-color: white;
    box-shadow: 0 0 6px 0 #121a2b14;
    display: flex;
    z-index: 999;
    border-radius: 0;
    padding: 20px 0;
    .alert-content-wrapper {
        display: flex;
        align-items: flex-start;
        .alert-content {
            display: inherit;
            align-items: inherit;
            padding-right: 6px;
            @include media-breakpoint-down(sm) {
                padding-right: 10px;
            }
            img {
                width: 29px;
                height: auto;
                margin-right: 10px;
                @include media-breakpoint-down(lg) {
                    width: 20px;
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
            p,a {
                font-size: 18px;
                line-height: 28px;
                font-weight: $font-medium;
                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            a {
                text-decoration: underline;
            }
        }
        .btn-close {
            position: static;
            background: #F0F0F0;
            border-radius: 50vw;
            width: 36px;
            height: 36px;
            display: grid;
            place-content: center;
            padding: 0;
            margin-left: auto;
            flex-shrink: 0;
            img {
                width: 10px;
                height: 10px;
            }
        }
    }
}