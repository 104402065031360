.publishers {
	section.hero {
		margin-top: 54px;
    	padding-bottom: 100px;
    @include media-breakpoint-down (md) {
      padding-bottom: 90px;
    }
		h1 {
			font-size: 68px;
			line-height: 76px;
			font-weight: $font-light;
			text-align: center;
			margin-bottom: 24px;
      @include media-breakpoint-down(lg) {
        font-size: 52px;
        line-height: 70px;
      }
			span {
				display: block;
				width: fit-content;
				margin: auto;
				margin-top: 10px;
				font-weight: $font-regular;
				padding: 1px 18px;
			}
		}
	
		p.section-lead {
			text-align: center;
      margin: auto;
			margin-bottom: 30px;
		}

    .green-list-container {
      display: flex;
      margin: auto;
      align-items: center;
      margin-bottom: 35px;
      padding: 0px;
      @include media-breakpoint-up(lg) {
        width: fit-content;
      }
      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 28px;
      }
      .green-list-item {
        height: fit-content;
        list-style: none;
        padding: 10px 14px 12px 10px;
        border-radius: 11px;
        background-color: #e4fcec;
        font-size: 20px;
        margin-bottom: 0px;
        box-shadow: 1px 2px 4px rgba(35, 43, 45, 0.1);
        &:not(:last-child) {
          margin-right: 16px;
        }
        img {
          margin-right: 10px;
        }
        @include media-breakpoint-down(lg) {
          margin-bottom: 12px;
          margin-right: 12px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
	
    .cta-button {
      display: flex;
      align-items: center;
      width: fit-content;
      border-radius: 36px;
      margin: auto;
      padding: 14px 30px 20px 30px;
      margin-bottom: 61px;
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        padding: 15px 30px 15px 30px;
      }
      img {
        margin-left: 13px;
      }
    }
     
		.hero-video-container {
			iframe {
				border-radius: 40px;
				width: 100%;
				aspect-ratio: 16 / 9;
        position:relative;
        z-index: 1;
				@include media-breakpoint-down(md) {
					border-radius: 25px;
					left: 0px;
					width: 100%;
				}
			}
		}

    .side-cards-container {
      position: absolute;
      width: 1600px;
      left: 50%;
      top: 300px;
      background-color: pink;
      transform: translateX(-50%);
      display: block;
      @include media-breakpoint-down(lg) {
        display: none;
      }
      .card {
          width: 198px;
          height: 179px;
          border-radius: 25px;
          border: none;
          box-shadow: 1px 2px 6px rgba(35, 43, 45, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon-container {
            width: 61px;
            height: 61px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
            border-radius: 50%;
            margin-bottom: 10px;
            img {
              width: 34px
            }
          }

          .green {
            background-color: #DBFFDE;
          }
          .purple {
            background-color: #F5E8FF;
          }
          .dark-blue {
            background-color:#E4F0FF;
          }
          .light-blue {
            background-color: #E0FAFF;
          }
          .pink {
            background-color:#FFF0F2;
          }
          .yellow {
            background-color: #F0FFB8;
          }

          .card-text {
            font-size: 20px;
            text-align: center;
            .category {
              display: block;
              font-weight: $font-bold;
            }
          }
      }

      .left-cards-container {
        position: absolute;
        left: 90px;
        top: 0px;
        z-index: 0;
        .card {
          &:nth-child(1) {
            transform: rotate(-5deg);
          }
          &:nth-child(2) {
            transform: rotate(5deg);
            margin-top: -10px;
            margin-left: -50px;
          }
          &:nth-child(3) {
            transform: rotate(-10deg);
            margin-top: 30px;
          }
        }
      }

      .right-cards-container {
        position: absolute;
        top: 0px;
        right: 30px;
        z-index: 0;
        .card {
          &:nth-child(1) {
            transform: rotate(10deg);
          }
          &:nth-child(2) {
            transform: rotate(-10deg);
            margin-top: 20px;
            margin-left: 80px;
          }
          &:nth-child(3) {
            transform: rotate(-3deg);
            margin-top: -10px;
          }
        }
      }

    }

	}
	
	section.cta {
		background-color: #AAFF3F;
	}

	section.company-carousel {
		margin-bottom: 110px;
		@include media-breakpoint-down(md) {
			margin-bottom: 90px;
		}
	}
	
	section.customer-success {
		.section-heading {
			.line {
				width: 920px;
			}
		}
	
		.success-stories {
			margin-top: 60px;
			@include media-breakpoint-down(md) {
				margin-top: 35px;
			}
			.card-success {
				margin-bottom: 15px;
			}
		}
	}

	section.how-it-works {
		margin-bottom: 100px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 40px;
		}
	}

  section.marketing {
		margin-bottom: 130px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 90px;
    }

    .section-heading {
      .line {
        @include media-breakpoint-down(sm) {
          left: 160px;
        }
      }
    }

    .section-lead {
      @include media-breakpoint-down(sm) {
        margin-bottom: 35px;
      }
    }

    .cards-container {
      .col-12 {
        justify-content: space-between;
        display: flex;
        @include media-breakpoint-down(lg) {
          display: block;
        }
      }

      .col-lg-7 {
        @include media-breakpoint-up(lg) {
          padding-right: 34px;
        }
        .card {
          background-color: #DBFFDE;
          @include media-breakpoint-down(lg) {
            width: 100%;
            margin-bottom: 25px;
            margin-right: 0px;
          }
          .card-title {
            padding-right: 18px;
            color: #121A2B;
            margin-bottom: 20px;
          }
        }
      }

      .col-lg-5 {
        .card {
          background-color: #F0FFB8;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
          .card-title {
            padding-right: 38px;
          }
        }
      }

      .card {
        height: fit-content;
        border: none;
        padding: 45px;
        padding-bottom: 60px;
        border-radius: 35px;
        @include media-breakpoint-down(lg) {
          height: fit-content;
          padding: 25px;
        }

        .card-header {
          padding: 0;
          .image-container {
            height: 266px;
            overflow: hidden;
            border-radius: 21px;
            margin-bottom: 25px;
            img {
              border-radius: 21px;
              width: 100%;
              object-fit: contain;
              @include media-breakpoint-down(sm) {
                height: 100%;
                width: auto;
              }
            }
          }

          .card-title-container {
            display: flex;
            justify-content: space-between;
            @include media-breakpoint-down(lg) {
              flex-direction: column-reverse;
            }
            .card-title-icon {
              height: 84px;
              @include media-breakpoint-down(lg) {
                width: 60px;
                margin-bottom: 12px;
              }
            }
          }
        }

        .card-body {
          padding: 0;
          .card-description {
            margin-bottom: 16px;
          }
          .card-list {
            margin-top: 16px;
            padding: 0px;
            .card-list-item {
              margin-bottom: 12px;
              display: flex;
              @include media-breakpoint-down(sm) {
                align-items: center;
              }
              img {
                margin-right: 11px;
                @include media-breakpoint-down(sm) {
                  margin-top: 2px;
                }
              }
            }
          }
        }

      }
    }
  }

  //@TODO refactor this and utilize newsletter style
	section.eligibility {
		margin-bottom: 100px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
    .container {
      @include media-breakpoint-only(md) {
        margin: 0px;
        padding: 0px;
      }
    }

    .col-lg-12 {
      @include media-breakpoint-down(sm) {
        padding: 0px;
      }
    }

		.eligibility-container {
			flex-direction: row;
			align-items: flex-end;
			padding: 64px 0 90px 90px;
      width: calc(100% + 180px);
      left: -90px;
      position: relative;
      background-color: #191D24;
      border-radius: 35px;
      display: flex;
      text-align: center;


			@include media-breakpoint-down(lg) {
				padding: 40px 40px 50px 40px;
        width: 100vw;
        margin: 0px;
        display: flex;
        left: 0;
			} 

      @include media-breakpoint-only(md) {
        justify-content: space-between;
        align-items: flex-start;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }

      h2,p {
          color: white;
          padding: 0 236px;
          @include media-breakpoint-down(md) {
              padding: 0px
          }
      }
      h2 {
          margin-bottom: 22px;
          @include media-breakpoint-down(lg) {
              padding: 0;
          }
          @include media-breakpoint-down(md) {
              margin-bottom: 16px;
          }
      }
      p {
          @include media-breakpoint-down(lg) {
              font-size: 18px;
              line-height: 28px;
              padding: 0 120px;
          }
          @include media-breakpoint-down(md) {
              padding: 0;
          }
      }
			.heading-container {
				padding-right: 30px;
				@include media-breakpoint-down(sm) {
					width: 100%;
					padding-right: 0px;
				}
				.section-heading {
					text-align: left;
					padding: 0;
          .block {
            display: inline;
          }
					span { 
            @include media-breakpoint-down(sm) {
              display: block;
            }
          }
					@include media-breakpoint-only(md) {
						font-size: 34px;
            line-height: 46px;
					}
				}
				.section-lead {
					padding: 0;
          text-align: left;
					margin-bottom: 49px;
					@include media-breakpoint-down(lg) {
						margin-bottom: 25px;
					}
					@include media-breakpoint-down(sm) {
					}
				}

        .custom-input {
          display: block;
          width: 462px;
          margin-bottom: 30px;
        }
			}

      .image-container {
        height: 418px;
        border-radius: 21px;
        overflow: hidden;
        margin-left: 24px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 20px;
          margin-left: 0;
        }
        @include media-breakpoint-only(md) {
          height: 350px;
          margin-bottom: 0px;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          height: 200px;
        }
        img {
          width: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
			button {
				width: 235px;
        height: auto;
				padding: 16px 0 18px 0;
        display: block;
        @include media-breakpoint-down(sm) {
          margin: auto;
        }
			}
		}
	}

  section.results {
    padding-bottom: 176px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 164px;
    }
    .case-study-card {
      border: none;
      border-radius: 35px;
      background-image: linear-gradient(134deg, rgba(224, 250, 255, 1) 0%, rgba(203, 232, 255, 1) 77%, rgba(197, 236, 255, 1) 100%);
      padding-top: 80px;
      padding-bottom: 95px;
      margin-bottom: 60px;
      @include media-breakpoint-down(lg) {
        padding: 30px 40px 40px 40px;
      }
      @include media-breakpoint-down(sm) {
        padding: 34px 25px 40px 25px;
        margin-bottom: 50px;
      }

      .card-body {
        @include media-breakpoint-up(lg) {
          margin: auto;
        }
        @include media-breakpoint-down(lg) {
          padding: 0px;
        }
        .card-text-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 50px;
          @include media-breakpoint-down(lg) {
            display: block;
          }
          @include media-breakpoint-down(lg) {
            margin-bottom: 30px;
          }
          .card-titles-container {
            margin-right: 50px;
            .sub-title {
              margin-bottom: 15px;
              font-size: 18px;
              font-weight: $font-bold;
              color: #005CD9;
            }
          }
        }

        .stats-card-container {
          display: flex;
          justify-content: space-between;
          .col-lg-4:not(:last-child) {
            @include media-breakpoint-up(lg) {
              width: 30%;
              padding-right: 15px;
            }
          }
          .col-lg-4:last-child {
            @include media-breakpoint-up(lg) {
              width:35%;
            }
          }
          .col-md-6 {
            @include media-breakpoint-only(md) {
              width: fit-content;
            }
          }
          @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
          }
          @include media-breakpoint-down(sm) {
            display: block;
          }
          .card {
            border: none;
            border-radius: 25px;
            background-color: #005CD9;
            height: 186px;
            padding: 0px;
            padding-top: 35px;
            @include media-breakpoint-down(lg) {
              padding-top: 24px;
              width: 296px;
              height: 160px;
            }
            @include media-breakpoint-down(sm) {
              margin: auto;
              margin-bottom: 20px;;
            }
            .card-body {
              padding: 0px;
              .stats {
                color: #fff;
                text-align: center;
                font-size: 65px;
                font-weight: $font-bold;
                line-height: 50px;
                margin-bottom: 15px;
                @include media-breakpoint-down(sm) {
                  font-size: 55px;
                }
                sub {
                  font-size: 30px;
                  font-weight: $font-bold;
                  bottom: 0px;
                }
              }
              .stat-description {
                width: 200px;
                text-align: center;
                color: #fff;
                font-weight: $font-medium;
                @include media-breakpoint-down(lg) {
                  margin: auto;
                  margin-top: -10px;
                }
              }
            }
          }

          .card-3 {
            padding: 34px 34px 34px 38px;
            @include media-breakpoint-down(lg) {
              margin: auto;
              margin-top: 24px;
            }
            .card-body {
              display: flex;
              align-items: center;
              margin: auto;
              padding: 0px;
              img {
              @include media-breakpoint-down(lg) {
                  width: 100px;
                }
              }
              p {
                color: white;
                width: 122px;
                margin-left: 30px;
                font-weight: $font-medium;
              }
            }
          }
        }
      }
    }

    .testimonial-heading {
      margin: auto;
      margin-bottom: 60px;
      font-size: 46px;
      text-align: center;
      font-weight: $font-regular;
      @include media-breakpoint-down(lg) {
        margin: auto;
        margin-bottom: 35px;
        font-size: 32px;
        line-height: 45px;
      }
      span {
        display: block;
        font-family: $font-secondary;
        font-weight: $font-medium;
        font-style: italic;
      }
    }

    .testimonial-videos {
      display: flex;
      .testimonial-video-thumbnail {
        height: 416px;
        overflow: hidden;
        border-radius: 25px;
        position: relative;
        @include media-breakpoint-only(md) {
          height: fit-content;
          margin-top: 60px;
        }
        .thumbnail-image {
          object-fit: cover;
          filter: brightness(0.6);
          width: 100%;
        }

        .play-button {
          position: absolute;
          top: 20px;
          left: 15px;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #121A2B;
          color: white;
          span {
            margin-left: 3px;
            font-size: 13px;
          }
        }
      }
    }
    .slider-pagination {
      right: 340px;
      margin-top: 33px;
      @include media-breakpoint-only(md) {
        right: 200px;
      }
      @include media-breakpoint-down(sm) {
        width: 50px;
      }
    }
  }

  section.features {
    padding-bottom: 130px;
    @include media-breakpoint-only(md) {
      padding-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 85px;
    }

    p.section-lead {
      @include media-breakpoint-down(sm) {
        margin-bottom: 35px;
      }
    }

    .section-heading {
      .line {
        @include media-breakpoint-down(sm) {
          left: 120px;
        }
      }
    }

    .features-container {
      .row {
        @include media-breakpoint-only(md) {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
        .col-md-6 {
          &:nth-child(1) {
            @include media-breakpoint-only(md) {
                margin-right: 36px
            }
          }
          @include media-breakpoint-only(md) {
            width: fit-content;
            padding: 0px;
          }
        }
        .card-3 {
          @include media-breakpoint-only(md) {
            margin: auto;
            margin-top: 36px;
          }
        }
      }
    }
    .card-feature {
      width: 356px;
      min-height: 336px;
      padding-bottom: 40px;
      background-color: #DEEBFB;
      border: none;
      border-radius: 35px;
      @include media-breakpoint-only(md) {
        width: 330px;
        height: 232px;
      } 
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 25px;
        height: 232px;
      }

      .card-body {
        padding: 0px 30px;
        @include media-breakpoint-down(sm) {
          padding: 0px 25px;
        }
      }
      
      .image-container {
        height: 92px;
        margin: auto;
        margin-top: 55px;
        margin-bottom: 25px;
        @include media-breakpoint-down(lg) {
          height: 60px;
          margin-top: 35px;
          margin-bottom: 18px;
        }
      }

      .icon {
        height: 100%;
        margin: auto;
        display: block;
      }

      .feature-title {
        font-weight: $font-bold;
        font-size: 26px;
        text-align: center;
        margin-bottom: 16px;
        @include media-breakpoint-down(lg) {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 8px;
        }
      }

      .feature-description {
        text-align: center;
      }
    }
  }

  .section-heading {
    .line {
      left: 300px;
      @include media-breakpoint-down(lg) {
        left: 175px;
      }
    }
  }
}

